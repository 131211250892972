<template>
  <b-card class="px-3 mb-3">
    <b-row>
      <b-img class="logoAseguradora" :src="oferta.aseguradora.logo" />

      <b-col class="text-left">
        <h4 class="text-muted strong"> {{oferta.aseguradora.descripcion}} </h4>
        <p>{{ oferta.detalle }}</p>
      </b-col>
    </b-row>

    <h5 class="text-left text-muted">Coberturas</h5>
    <b-row cols="12">
      <b-table
        class="bg-white text-left"
        thead-class="d-none"
        hover small
        :items="condiciones"
      >
        <template #cell(descripcion)="data">
          <b class=" text-capitalize">{{
            data.value
          }}</b>
        </template>
        <template #cell(valor)="data">
          <b class=" text-capitalize">${{
            data.value
          }}</b>
        </template>
      </b-table>
    </b-row>

    <b-row class="d-flex justify-content-end">
        <div
          class="d-flex justify-content-around grounded px-2 py-1 mr-2 align-items-stretch border rounded"
        >
          <strong>Total: </strong>
          <span>${{ total }}</span>
          <!--<span> | {{ personas ? personas : 1 }}
            <i class="fas fa-male"></i> | {{ dias ? dias : 30 }}
            <i class="fas fa-calendar-day"></i>
          </span>-->
        </div>
        <b-button
          class="btn-oferta"
          @click="irAContratar()"
          style="white-space: nowrap"
          >contratar
        </b-button>
    </b-row>  
  </b-card>
</template>

<script>
export default {
  name: "oferta",

  props: {
    oferta: {
      types: [Object],
    },
    personas: {
      types: [Number, String],
    },
    fechainicio: {
      types: String,
    },
    fechafin: {
      types: String,
    }
  },
  computed: {
    total: function () {
      return (
        Math.round(this.oferta.precio_base * (this.dias/30) * this.personas * 100) /
        100
      ).toFixed(2);
    },
    dias: function () {
      var count = 0;
      if (this.fechainicio && this.fechafin) {
        count = Math.round(
          Math.abs(
            (new Date(this.fechainicio) - new Date(this.fechafin)) / 86400000
          )
        );
        return count + 1;
      }
      return null;
    },
    condiciones: function() {
      let condiciones = [];
      if (this.oferta && this.oferta.atributo) {
        this.oferta.atributo.forEach((element) => {
          if (!element.clausula) {
            condiciones.push({
              descripcion: element.descripcion,
              valor: element.valor,
            });
          }
        });
      }
      return condiciones;
    },
  },
  data() {
    return {
      fields: [{ key: "descripcion" }, { key: "valor" }],
    };
  },
  methods: {
    irAContratar() {
      this.$router
        .push({
          path: `/contratar-${this.oferta.oferta_id}/${this.fechainicio}/${this.fechafin}`,
        })
        .catch((err) => {
          err;
        });
    },
  },
};
</script>

<style scoped>
.btn-oferta {
  background-color: #2c3e50;
  border-color: #27337a;
}
.btn-oferta:hover {
  color: #ffffff !important;
  background-color: #27337a;
  border-color: #27337a;
}

.btn-pdf {
background-color: white;
border-color: #27337a;
color: #27337a;
}
.btn-pdf:hover {
  background-color: rgb(236, 245, 255);
  border-color: #35449c;
  color: #27337a;
}

hr {
  margin-top: 0px;
}

b {
  font-size: 0.90rem;
  font-weight: 100;
}

.logoAseguradora {
  max-block-size: 60px;
}
</style>