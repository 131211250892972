<template>
  <div id="footer" class="mt-4">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-6">
          <h4>Páginas</h4>
          <ul>
            <li><a href="/">Inicio</a></li>
            <li>
              <a href="http://brokerandino.com.ar/" target="_blanc"
                >Broker Andino</a
              >
            </li>
            <li>
              <a href="http://brokerandino.com.ar/contacto/" target="_blanc"
                >Contacto</a
              >
            </li>
            <li>
              <a href="http://brokerandino.com.ar/novedades/" target="_blanc"
                >Novedades</a
              >
            </li>
          </ul>
          <!-- <hr /> -->
        </div>

        <div class="col-md-3 col-sm-6">
          <h4>Usuario</h4>
          <ul>
            <li>
              <a href="/coberturas" class="">Mis coberturas</a>
            </li>
          </ul>
        </div>
        <div class="col-md-3 col-sm-6">
          <h4>Asistencia</h4>
          <p>
            <strong>Horarios de atención</strong> <br />Lunes a Viernes
            <br />9:00hs a 17:00hs
          </p>
          <p>
            <strong>Teléfonos</strong>
            <br /><i class="fas fa-phone-alt"></i
            ><a href="tel:4214273"> 4214273 </a><br /><i
              class="fab fa-whatsapp"
            ></i>
            <a href="tel:+542645144910"> +54 264 5144910</a> <br />
            <i class="fab fa-whatsapp"></i>
            <a href="tel:+542644685648"> +54 264 4685648</a>
          </p>
          <div hidden>
            <hr />
            <h4>Redes Sociales</h4>
            <p class="social">
              <a href="https://www.facebook.com/"
                ><i class="fab fa-facebook fa-3x"></i>
              </a>
              <a href="https://www.twitter.com/"
                ><i class="fab fa-twitter fa-3x"></i>
              </a>
              <a href="https://www.instagram.com/"
                ><i class="fab fa-instagram fa-3x"></i>
              </a>
            </p>
          </div>
        </div>

        <div class="col-md-3 col-sm-6" hidden>
          <h4>Otros enlaces</h4>
          <p>
            <a href="" target="_blanc">Enlace 1</a><br />
            <a href="" target="_blanc">Enlace 1</a><br />
            <a href="" target="_blanc">Enlace 1</a><br />
          </p>
          <h4>Titulo</h4>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi
          veritatis suscipit explicabo laboriosam esse
          <hr />
          <h4>Titulo</h4>

          <a href="https://ssl.comodo.com" id="comodoTL">Comodo SSL</a>
        </div>
        <!-- /.col-md-3 -->
        <div class="col-md-3 col-sm-6">
          <h4>Estamos comprometidos</h4>

          <p class="text-muted">
            Somos una empresa comprometida en generar impacto positivo en la
            sociedad. Apoyamos y participamos activamente de iniciativas
            deportivas, formativas, de salud y de inclusión.
          </p>
          <p class="text-muted small">
            <a href="https://ssn.gob.ar/">www.ssn.gob.ar</a> 0800 - 666 - 8400
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BrFooter",
  props: {
    msg: String,
  },
  methods: {
    test() {
      console.log("test");
    },
  },
};
</script>

<style>
#footer {
  background: #e0e0e0;
  padding: 20px 0;
  text-align: left;
  color: #fefefe;
  background: linear-gradient(90deg, #27337a 10%, #583c85 90%);
}

#footer ul a {
  color: #e0e0e0;
}

#footer p a {
  color: #e0e0e0;
}

#footer a {
  color: #e0e0e0;
}

#footer hr {
  border-top: 1px solid #b54c90;
}
#footer ul {
  padding-left: 0;
  list-style: none;
}

#footer .social a {
  padding: 0.5em;
}
#footer .text-muted {
  color: #e667ba !important;
}
</style>