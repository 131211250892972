<template>
  <div>
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <!-- Documento -->

      <b-form-group
        :state="tipoState"
        label="Tipo de documento:"
        label-for="tipo-input"
        invalid-feedback="Tipo es requerido"
        label-cols="12"
        label-cols-md="4"
        label-align-md="right"
      >
        <b-form-select
          id="tipo-input"
          :options="options"
          v-model="tipo"
          :state="tipoState"
          ref="tipo"
          required
        ></b-form-select>
      </b-form-group>

      <!-- Numero de documento -->
      <b-form-group
        :state="numeroDocumentoState"
        :label="`Numero de ${tipo}:`"
        label-for="numero-documento-input"
        invalid-feedback="Numero documento es requerido"
        label-cols="12"
        label-cols-md="4"
        label-align-md="right"
      >
        <b-form-input
          id="numero-documento-input"
          type="text"
          v-model="numeroDocumento"
          :state="numeroDocumentoState"
          required
          ref="numeroDocumento"
        ></b-form-input>
      </b-form-group>

      <!-- nombre -->
      <b-form-group
        :state="nombreState"
        :label="personaJuridica ? 'Razón social:' : 'Nombre:'"
        label-for="nombre-input"
        invalid-feedback="Nombre es requerido"
        label-cols="12"
        label-cols-md="4"
        label-align-md="right"
      >
        <b-form-input
          id="nombre-input"
          type="text"
          v-model="nombre"
          :state="nombreState"
          required
          ref="nombre"
        ></b-form-input>
      </b-form-group>

      <!-- apellido -->
      <b-form-group
        v-if="!personaJuridica"
        :state="apellidoState"
        label="Apellido:"
        label-for="apellido-input"
        invalid-feedback="Apellido es requerido"
        label-cols="12"
        label-cols-md="4"
        label-align-md="right"
      >
        <b-form-input
          id="apellido-input"
          type="text"
          v-model="apellido"
          :state="apellidoState"
          required
          ref="apellido"
        ></b-form-input>
      </b-form-group>

      <!-- codigo postal -->
      <b-form-group
        :state="cpState"
        :label="`CP:`"
        label-for="cp-input"
        invalid-feedback="Codigo postal es requerido"
        label-cols="12"
        label-cols-md="4"
        label-align-md="right"
      >
        <b-form-input
          id="cp-input"
          type="text"
          v-model="cp"
          :state="cpState"
          required
          ref="cp"
        ></b-form-input>
      </b-form-group>

      <!-- localidad -->
      <b-form-group
        :state="localidadState"
        :label="`Localidad:`"
        label-for="localidad-input"
        invalid-feedback="Localidad es requerido"
        label-cols="12"
        label-cols-md="4"
        label-align-md="right"
      >
        <b-form-input
          id="localidad-input"
          type="text"
          v-model="localidad"
          :state="localidadState"
          required
          ref="localidad"
        ></b-form-input>
      </b-form-group>

      <!-- provincia -->
      <b-form-group
        :state="provinciaState"
        label="Provincia:"
        label-for="provincia-input"
        invalid-feedback="Provincia es requerida"
        label-cols="12"
        label-cols-md="4"
        label-align-md="right"
      >
        <b-form-select
          id="provincia-input"
          :options="provincias"
          v-model="provincia"
          :state="provinciaState"
          ref="provincia"
          required
        ></b-form-select>
      </b-form-group>

      <!-- Domicilio -->

      <b-form-group
        :state="domicilioState"
        label="Domicilio:"
        label-for="domicilio-input"
        invalid-feedback="Domicilio es requerido"
        label-cols="12"
        label-cols-md="4"
        label-align-md="right"
      >
        <b-form-input
          id="domicilio-input"
          :options="options"
          v-model="domicilio"
          :state="domicilioState"
          ref="domicilio"
          required
        ></b-form-input>
      </b-form-group>

      <!-- telefono -->
      <b-form-group
        :state="telefonoState"
        :label="`Telefono:`"
        label-for="telefono-input"
        invalid-feedback="Telefono es requerido"
        label-cols="12"
        label-cols-md="4"
        label-align-md="right"
      >
        <b-form-input
          id="telefono-input"
          type="text"
          v-model="telefono"
          :state="telefonoState"
          required
          ref="telefono"
        ></b-form-input>
      </b-form-group>
      <!-- fecha nacimiento -->
      <b-form-group
        :state="fechaNacimientoState"
        label="Fecha Nacimiento:"
        label-for="fecha-nacimiento-input"
        invalid-feedback="Fecha es requerida"
        label-cols="12"
        label-cols-md="4"
        label-align-md="right"
      >
        <datetime
          id="fecha-nacimiento-input"
          :inputClass="{
            'form-control ': true,
            'is-invalid': fechaNacimientoState === false,
            'is-valid': fechaNacimientoState === true,
          }"
          v-model="fechaNacimiento"
          type="date"
          :format="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          :flow="['year', 'date']"
          value-zone="America/Argentina/Buenos_Aires"
          placeholder="Fecha de nacimiento"
          zone="America/Argentina/Buenos_Aires"
          :phrases="{ ok: 'Aceptar', cancel: 'Cancelar' }"
          :max-datetime="new Date().toISOString()"
          ref="fechaNacimiento"
        ></datetime>
      </b-form-group>

      <!-- email -->
      <b-form-group
        :state="emailState"
        label="Email:"
        label-for="email-input"
        invalid-feedback="Email es requerido"
        label-cols="12"
        label-cols-md="4"
        label-align-md="right"
      >
        <b-form-input
          id="email-input"
          type="email"
          v-model="email"
          :state="emailState"
          required
          ref="email"
        ></b-form-input>
      </b-form-group>

      <!-- repetir email -->
      <b-form-group
        :state="emailRepetidoState"
        label="Confirmar Email:"
        label-for="email-repetido-input"
        invalid-feedback="Email es requerido"
        label-cols="12"
        label-cols-md="4"
        label-align-md="right"
      >
        <b-form-input
          id="email-repetido-input"
          type="email"
          v-model="emailRepetido"
          :state="emailRepetidoState"
          required
          ref="emailRepetido"
        ></b-form-input>
      </b-form-group>

      <!-- Contraseña -->
      <b-form-group
        :state="passwordState"
        label="Contraseña:"
        label-for="password-input"
        invalid-feedback="Contraseña es requerida"
        label-cols="12"
        label-cols-md="4"
        label-align-md="right"
      >
        <b-form-input
          id="password-input"
          type="password"
          v-model="password"
          :state="passwordState"
          ref="password"
          required
        ></b-form-input>
      </b-form-group>

      <!-- Repetir Contraseña -->
      <b-form-group
        :state="passwordRepetidoState"
        label="Repetir contraseña:"
        label-for="password-repetido-input"
        invalid-feedback="Las contraseñas deben coincidir"
        label-cols="12"
        label-cols-md="4"
        label-align-md="right"
      >
        <b-form-input
          id="password-repetido-input"
          type="password"
          v-model="passwordRepetido"
          :state="passwordRepetidoState"
          ref="passwordRepetido"
          required
        ></b-form-input>
      </b-form-group>
      <b-card class="bg-danger m-0 p-0"
        ><b-card-body class="m-0 p-0 text-light">
          <i class="fas fa-info-circle"></i> Al registrarte se te enviará un
          correo a tu email para verificar la cuenta, recuerda revisar tu
          casilla de spam.</b-card-body
        ></b-card
      >
      <div class="my-2">
        <a href="#" @click.prevent="recoverPassword" hidden
          >Olvidé mi contraseña</a
        >
        <a href="#" @click.prevent="showLogin">Tengo cuenta</a>
      </div>
    </form>

    <b-row align-h="between" class="px-3">
      <b-button @click="hideModal">Cancelar</b-button>
      <b-button @click="registerCheck">Registrarse</b-button>
    </b-row>
  </div>
</template>

<script>
import Api from "@/client/api";
import Provincias from "@/client/data/provincias.js";

export default {
  name: "register",
  apellido: "register",
  data() {
    return {
      tipo: "DNI",
      tipoState: null,
      numeroDocumento: "",
      numeroDocumentoState: null,
      nombre: "",
      apellido: "",
      nombreState: null,
      apellidoState: null,
      domicilio: "",
      domicilioState: null,
      provincia: "",
      provinciaState: null,
      localidad: "",
      localidadState: null,
      cp: "",
      cpState: null,
      email: "",
      emailState: null,
      emailRepetido: "",
      emailRepetidoState: null,
      password: "",
      passwordState: null,
      passwordRepetido: "",
      passwordRepetidoState: null,
      fechaNacimiento: "",
      fechaNacimientoState: null,
      telefono: "",
      telefonoState: null,
      options: [
        { value: null, text: "Tipo de documento", disabled: true },
        { value: "DNI", text: "DNI" },
        { value: "CUIT", text: "CUIT" },
      ],
      provincias: Provincias,
    };
  },
  computed: {
    personaJuridica: function() {
      if (this.tipo == "CUIT") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    checkFormValidity() {
      var valid = true;
      if (this.tipo) {
        this.tipoState = true;
      } else {
        this.tipoState = false;
        valid = false;
      }
      if (this.$refs.cp.checkValidity()) {
        this.cpState = true;
      } else {
        this.cpState = false;
        valid = false;
      }
      if (this.fechaNacimiento) {
        this.fechaNacimientoState = true;
      } else {
        this.fechaNacimientoState = false;
        valid = false;
      }
      if (this.$refs.localidad.checkValidity()) {
        this.localidadState = true;
      } else {
        this.localidadState = false;
        valid = false;
      }
      if (this.provincia) {
        this.provinciaState = true;
      } else {
        this.provinciaState = false;
        valid = false;
      }
      if (this.$refs.domicilio.checkValidity()) {
        this.domicilioState = true;
      } else {
        this.domicilioState = false;
        valid = false;
      }
      if (this.$refs.numeroDocumento.checkValidity()) {
        this.numeroDocumentoState = true;
      } else {
        this.numeroDocumentoState = false;
        valid = false;
      }
      if (this.$refs.telefono.checkValidity()) {
        this.telefonoState = true;
      } else {
        this.telefonoState = false;
        valid = false;
      }
      if (this.$refs.nombre.checkValidity()) {
        this.nombreState = true;
      } else {
        this.nombreState = false;
        valid = false;
      }
      if (!this.personaJuridica) {
        if (this.$refs.apellido.checkValidity()) {
          this.apellidoState = true;
        } else {
          this.apellidoState = false;
          valid = false;
        }
      }
      if (this.$refs.email.checkValidity()) {
        this.emailState = true;
      } else {
        this.emailState = false;
        valid = false;
      }
      if (this.$refs.emailRepetido.checkValidity()) {
        this.emailRepetidoState = true;
      } else {
        this.emailRepetidoState = false;
        valid = false;
      }
      if (this.$refs.password.checkValidity()) {
        this.passwordState = true;
      } else {
        this.passwordState = false;
        valid = false;
      }
      if (this.$refs.passwordRepetido.checkValidity()) {
        if (this.password == this.passwordRepetido) {
          this.passwordRepetidoState = true;
        } else {
          this.passwordRepetidoState = false;
          valid = false;
        }
      } else {
        this.passwordRepetidoState = false;
        valid = false;
      }
      return valid;
    },

    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-register", "#btnShow");
    },
    registerCheck() {
      if (!this.checkFormValidity()) {
        return;
      }

      Api.registrarse({
        tipo_documento: this.tipo,
        name: this.nombre,
        apellido: this.apellido,
        email: this.email,
        password: this.password,
        dni: this.numeroDocumento,
        domicilio: this.domicilio,
        cp: this.cp,
        localidad: this.localidad,
        provincia: this.provincia,
        telefono: this.telefono,
        fecha_nacimiento: this.fechaNacimiento,
        persona_juridica: this.tipo == "CUIT" ? 1 : 0,
      })
        .then((res) => {
          let message = "";
          let variant = "";
          let title = "";

          if (res.errors) {
            Object.entries(res.errors).forEach((entry) => {
              message = message + entry[1][0] + " ";
            });
            title = "Error";
            variant = "danger";
          } else {
            title = "Cuenta creada";
            message = res.message;
            variant = "success";
            setTimeout(() => {
              this.$nextTick(() => {
                this.hideModal();
              });
            }, 1000);
          }
          this.$bvToast.toast(message, {
            title,
            autoHideDelay: 5000,
            variant,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showLogin() {
      this.$root.$emit("bv::hide::modal", "modal-register", "#btnShow");
      this.$root.$emit("bv::show::modal", "modal-login", "#btnShow");
    },
    recoverPassword() {
      this.$root.$emit("bv::hide::modal", "modal-register", "#btnShow");
      this.$root.$emit("bv::show::modal", "modal-recover-password", "#btnShow");
    },
  },
};
</script>
