var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"yamm navbar navbar-expand-lg navbar-light bg-white border-bottom box-shadow mb-3"},[_c('div',{staticClass:"container w-100"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"navbar-collapse collapse",attrs:{"id":"navbar-collapse-1"}},[_c('ul',{staticClass:"navbar-nav mr-auto"},[_c('router-link',{attrs:{"to":{ name: 'home' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item dropdown yamm-fw",class:[
              isActive && 'router-link-active',
              isExactActive && 'router-link-exact-active' ]},[_c('a',{staticClass:"nav-link",attrs:{"href":href,"data-toggle":"dropdown"},on:{"click":navigate}},[_vm._v("Inicio"),_c('span',{staticClass:"line"},[_vm._v("|")])])])]}}])}),_c('li',{staticClass:"nav-item dropdown yamm-fw"},[_vm._m(2),_c('div',{staticClass:"dropdown-menu p-0 mt-0"},[_c('div',{staticClass:"yamm-content"},[_vm._m(3),_c('div',{staticClass:"row"},_vm._l((_vm.actividadesSplit),function(item,index){return _c('div',{key:index,staticClass:"col-sm-2"},_vm._l((item),function(subitem){return _c('ul',{key:subitem.actividad_id,staticClass:"col-sm-2"},[_c('li',[_c('a',{attrs:{"href":'/listar/' + subitem.actividad_id}},[_vm._v(_vm._s(subitem.descripcion))])])])}),0)}),0)])])]),_c('router-link',{attrs:{"to":{ name: 'Mis coberturas' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item dropdown yamm-fw",class:[
              isActive && 'router-link-active',
              isExactActive && 'router-link-exact-active' ]},[_c('a',{staticClass:"nav-link",attrs:{"href":href,"data-toggle":"dropdown"},on:{"click":navigate}},[_vm._v("Mis coberturas")])])]}}])}),(_vm.user.admin)?_c('router-link',{attrs:{"to":{ name: 'Admin home' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item dropdown yamm-fw",class:[
              isActive && 'router-link-active',
              isExactActive && 'router-link-exact-active' ]},[_c('a',{staticClass:"nav-link",attrs:{"href":href,"data-toggle":"dropdown"},on:{"click":navigate}},[_vm._v("Administración")])])]}}],null,false,3069371293)}):_vm._e()],1),_c('ul',{staticClass:"navbar-nav ml-auto"},[_c('li',{staticClass:"nav-item dropdown yamm-fw"},[(!_vm.user.login)?_c('a',{staticClass:"nav-link btn btn-header mx-2",attrs:{"href":"#","data-toggle":"dropdown"},on:{"click":function($event){return _vm.showLogin()}}},[_vm._v("Iniciar sesion")]):_c('span',[_vm._v(" Hola "+_vm._s(_vm.user.data.name)+" "),_c('a',{staticClass:"logout d-inline-block mx-2",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.logout()}}},[_c('i',{staticClass:"fas fa-sign-out-alt"}),_vm._v("Salir")])]),(!_vm.user.login)?_c('a',{staticClass:"nav-link btn btn-header mx-2",attrs:{"href":"#","data-toggle":"dropdown"},on:{"click":function($event){return _vm.showRegister()}}},[_vm._v("Registrarme")]):_vm._e()])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"navbar-brand",attrs:{"href":"#"}},[_c('div',{staticClass:"wrap-logo"},[_c('a',{attrs:{"href":"/"}},[_c('img',{staticClass:"logo-ba d-none d-md-block",attrs:{"alt":"logo","src":require("@/client/assets/vertical-color.png")}})]),_c('a',{attrs:{"href":"/"}},[_c('img',{staticClass:"logo-ba d-md-none",attrs:{"alt":"logo","src":require("@/client/assets/logo.png")}})])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbar-collapse-1","aria-controls":"navbar-collapse-1","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"href":"#","data-toggle":"dropdown"}},[_vm._v("Lista de actividades "),_c('span',{staticClass:"line"},[_vm._v("|")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-2"},[_c('h5',[_vm._v("Actividades")])])])}]

export { render, staticRenderFns }