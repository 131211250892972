<template>
  <b-container>
    <b-row class="px-md-4">
      <b-col md="12" cols="12">
        <template v-if="user.login">
          <b-card class="mb-3">
            <b-row>
              <b-col>
                <h3>Tus coberturas</h3>
              </b-col>
            </b-row>
          </b-card>
          <template v-if="polizas.length != 0">
            <poliza
              v-for="item in polizas"
              :key="item.poliza_id"
              :poliza="item"
              class="mb-3"
            />
          </template>
          <template v-else>
            <b-card
              ><b-card-text class="mt-4"
                >No tiene polizas contratadas</b-card-text
              ></b-card
            >
          </template>
        </template>
        <template v-else>
          <b-card
            ><b-card-text class="mt-4">Debe iniciar sesión</b-card-text></b-card
          >
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Api from "@/client/api.js"
import Poliza from "@/client/components/cliente/Poliza"

export default {
  name: "cobertua",
  async created() {
    await Api.getPoliza().then((res) => {
      this.polizas = res
    })
  },
  props: { user: Object },
  components: { Poliza },
  data() {
    return {
      polizas: [],
    }
  },
  watch: {
    "user.data": function () {
      Api.getPoliza().then((res) => {
        this.polizas = res
      })
    },
  },
}
</script>