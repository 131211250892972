<template>
  <div>
    <form id="formlogin" ref="form" @submit.prevent="handleSubmit">
      <b-form-group
        :state="emailState"
        label="Email"
        label-for="email-input"
        invalid-feedback="Email es requerido"
      >
        <b-form-input
          id="email-input"
          type="email"
          v-model="email"
          :state="emailState"
          required
          ref="email"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :state="passwordState"
        label="Contraseña"
        label-for="password-input"
        invalid-feedback="Contraseña es requerida"
      >
        <b-form-input
          id="password-input"
          type="password"
          v-model="password"
          :state="passwordState"
          ref="password"
          required
        ></b-form-input> </b-form-group
      ><b-card class="bg-light m-0 p-0" v-if="showValitedResend"
        ><b-card-body class="m-0 p-0">
          <i class="fas fa-info-circle"></i> Cuenta no verificada, reenviar
          email:
          <a href="#" @click.prevent="resendEmail">reenviar</a>
        </b-card-body></b-card
      >
      <div class="my-2 d-flex justify-content-between">
        <a href="#" @click.prevent="recoverPassword">Olvidé mi contraseña</a>
        <a href="#" @click.prevent="showRegister">Registrarse</a>
      </div>
    </form>

    <b-row align-h="between" class="px-3">
      <b-button @click="hideModal">Cancelar</b-button>
      <b-button type="submit" form="formlogin" @click="loginCheck"
        >Iniciar sesión</b-button
      >
    </b-row>
  </div>
</template>

<script>
import Api from "@/client/api"

export default {
  name: "login",
  props: { user: Object },
  data() {
    return {
      email: "",
      emailState: null,
      submittedNames: [],
      password: "",
      passwordState: null,
      showValitedResend: false,
      options: [
        { value: null, text: "Tipo de documento", disabled: true },
        { value: "DNI", text: "DNI" },
        { value: "CUIT", text: "CUIT" },
        { value: "LC", text: "LC" },
        { value: "LE", text: "LE" },
        { value: "Pasaporte", text: "Pasaporte" },
        { value: "Otro", text: "Otro" },
      ],
      ok: false,
    }
  },
  methods: {
    resendEmail() {
      if (this.email) {
        Api.get(`email/resend?email=${this.email}`)
          .then((data) => {
            this.$bvToast.toast(data.message, {
              title: "Correcto",
              autoHideDelay: 5000,
              variant: "success",
            })
            return data
          })
          .catch((error) => {
            console.log("Hubo un problema con la petición: " + error.message)
            this.$bvToast.toast(error.message, {
              title: "Error",
              autoHideDelay: 5000,
              variant: "danger",
            })
          })
      }
    },
    checkFormValidity() {
      var valid = true
      if (this.$refs.email.checkValidity()) {
        this.emailState = true
      } else {
        this.emailState = false
        valid = false
      }

      if (this.$refs.password.checkValidity()) {
        this.passwordState = true
      } else {
        this.passwordState = false
        valid = false
      }
      return valid
    },

    handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }
    },
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-login", "#btnShow")
    },
    loginCheck() {
      if (!this.checkFormValidity()) {
        return
      }

      Api.login({
        email: this.email,
        password: this.password,
        remember_me: this.remember_me ? 1 : 0,
      })
        .then((res) => {
          let message = ""
          let variant = ""
          let title = ""

          if (res.errors) {
            Object.entries(res.errors).forEach((entry) => {
              message = message + entry[1][0] + " "
            })
            if (res.message) {
              message += res.message
            }
            title = "Error"
            variant = "danger"
          } else if (res.message) {
            title = "Error"
            variant = "danger"
            message = res.message
            if (res.is_validated == false) {
              this.showValitedResend = true
            }
          } else {
            title = "Bienvenido"
            message = res.message ? res.message : "Iniciaste sesión con éxito"
            variant = "success"
            setTimeout(() => {
              this.$nextTick(() => {
                this.hideModal()
                // this.$router.go();
                this.$emit("login", res.access_token)
              })
            }, 1000)
          }
          this.showMessage(message, title, variant)
        })
        .catch((err) => {
          console.log(err)
        })
    },

    recoverPassword() {
      this.$root.$emit("bv::hide::modal", "modal-login", "#btnShow")
      this.$root.$emit("bv::show::modal", "modal-recover-password", "#btnShow")
    },
    showRegister() {
      this.$root.$emit("bv::hide::modal", "modal-login", "#btnShow")
      this.$root.$emit("bv::show::modal", "modal-register", "#btnShow")
    },
    showMessage(message, title, variant) {
      this.$root.$bvToast.toast(message, {
        title,
        autoHideDelay: 5000,
        variant,
        "no-auto-hide": true,
      })
    },
  },
}
</script>