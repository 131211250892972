<template>
  <b-card :title="mini ? 'Buscar' : 'CONTRATA TU SEGURO'">
    <b-card-sub-title v-if="!mini" class="mb-3">
      {{ `Contrata tu seguro de accidentes personales desde casa` }}
    </b-card-sub-title>
    <step-header :hidden="mini"/>
    
    <b-card-text class="mt-4" :class="{ 'mx-5': !mini }">
      <b-form>
        <b-row>
          <b-col class="col-12" :class="{ 'col-md-4 mb-3 ml-auto': !mini }">
            <b-form-group class="text-left" label="Actividad" label-for="">
              <b-form-select
                :options="getActivity"
                v-model="activity"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled>¿Qué actividad necesitas asegurar?</b-form-select-option>
                </template>
                <span slot="no-options" @click="$refs.select.open = false">
                  No hay opciones que coincidan.
                </span>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col class="col-12" :class="{ 'col-md-4 mb-3 ml-auto': !mini }">
            <b-form-group class="text-left" label="Subactividad" label-for="">
              <b-form-select
                :options="subactividadesSelect"
                v-model="subactivity"
                :disabled="activity == null"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled>Seleccioná la subactividad</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col class="col-12" :class="{ 'col-md-4 mb-3 mr-auto': !mini }">
            <b-form-group class="text-left" label="Personas" label-for="">
              <b-input-group append="Personas">
                <b-form-input
                  id="type-number"
                  type="number"
                  class="text-left"
                  placeholder="¿Cuantas personas?"
                  v-model="personas"
                  min="1"
                  @keypress="isNumber($event)"
                >
                </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-12" :class="{ 'col-md-4 ml-auto': !mini }">
            <!--<label for="start-date">¿Desde que fecha?</label>-->
            <b-form-group class="text-left" label="Inicio Vigencia" label-for="">
              <b-form-datepicker
                id="start-date"
                v-on:input="endDate == null"
                v-model="startDate"
                :min="min"
                :max="max"
                class="mb-2"
                locale="es-AR"
                placeholder="dd/mm/aaaa"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>

          <b-col class="col-12" :class="{ 'col-md-4  mr-auto': !mini }">
            <b-form-group class="text-left" label="Cantidad de Días" label-for="">
              <b-form-select
                :options="[30,60,90,120,150,180,210,240,270,300,330,360]"
                v-model="periodo"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled>¿Por cuantos días?</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <template v-if="!mini">
          <b-row>
            <b-col class="my-3">
              <router-link
                class="btn btn-form"
                :disabled="!validForm"
                :class="{ disabled: !validForm }"
                :event="validForm ? 'click' : ''"
                :to="{
                  name: 'listar',
                  params: {
                    actividad: activity,
                    subactividad: subactivity,
                    personas: parseInt(personas),
                    dias: dias,
                    inicio: startDate,
                    fin: endDate,
                    periodo: periodo,
                  },
                }"
              >
                {{ reversedMessage }}
              </router-link>
            </b-col>
          </b-row>
        </template>
        <template v-else>
          <a class="btn btn-form d-none" :disabled="!validForm">
            {{ reversedMessage }}
          </a>
        </template>
      </b-form>
    </b-card-text>
    <div :hidden="mini">
      <hr />
      <div class="d-flex">
        <img class="w-100" src="/mercadopago-largo.png" alt="" srcset="" />
      </div>
    </div>
  </b-card>
</template>

<script>
import StepHeader from "@/client/components/layout/StepHeader"

export default {
  name: "Search",
  props: {
    mini: Boolean,
    actividades: Array,
    subactividades: Array,
    selectedActividad: Number,
    selectedSubactividad: Number,
    selectedPersonas: Number,
    selectedInicio: String,
    selectedPeriod: Number
  },
  components: { StepHeader },
  mounted() {
    let lista = new Array();
    if (this.actividades) {
      this.actividades.forEach((element) => {
        var actividad = new Object();
        actividad.value = element.actividad_id;
        actividad.text = element.descripcion;
        lista.push(actividad);
      });
      this.actividadesSelect = lista;
    }
  },
  computed: {
    validForm: function() {
      return (
        //TODO: HACER LA VALIDACION DE SUBACTIVIDAD
        this.activity != null && this.personas != null && this.dias != null
      )
    },
    reversedMessage: function() {
      if (this.activity == null && this.personas == null && this.dias == null) {
        return "Completa los datos para poder cotizar";
      }
      if (this.activity == null) {
        return `Selecciona actividad`;
      } else {
        let checklist = [];
        this.subactividades.forEach(element => {
          if (this.activity == element.actividades_id) checklist.push(element.id);
        });
        //revisa si debe elegir subcategoria y si ha elegido alguna
        if ((checklist.length > 0) && !checklist.includes(this.subactivity)) return `Selecciona subactividad`;
      } 
      if (this.personas == null) {
        return `Selecciona para cuantas personas`;
      }
      if (this.startDate == null) {
        return `Selecciona fecha inicio de vigencia`;
      }
      if (this.periodo == null) {
        return `Selecciona la cantidad de días`;
      }
      this.searchEvent()
        var descripcion = 'vacio';
        this.actividades.forEach(atrib => {
          if (atrib.actividad_id == this.activity) descripcion = atrib.descripcion;
        });
        return `Cotizar ${this.personas} ${descripcion} por ${this.dias} dias`;
    },
    dias: function() {
      var count = 0
      if (this.startDate && this.endDate) {
        count = Math.round(
          Math.abs(
            (new Date(this.startDate) - new Date(this.endDate)) / 86400000
          )
        )
        return count + 1;
      }
      return null;
    },
    endDate: function() {
      if (this.periodo && this.startDate) {
        let endDate = new Date(this.startDate);
        endDate.setDate(endDate.getDate() + this.periodo - 1);
        return endDate.toISOString().split("T")[0];
      }
      return null;
    },
    maxDate: function() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth());

      let maxEndDate = new Date(today);
      if (this.startDate) {
        maxEndDate = new Date(this.startDate);
        maxEndDate.setDate(maxEndDate.getDate() + 366);
      } else {
        maxEndDate.setDate(now.getDate() + 366);
      }
      return maxEndDate;
    },
    minDate: function() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth());

      let minEndDate = new Date(today);
      if (this.startDate) {
        minEndDate = new Date(this.startDate);
        minEndDate.setDate(minEndDate.getDate() + 30);
      } else {
        minEndDate.setDate(now.getDate() + 30);
      }
      return minEndDate;
    },
    getActivity: function() {
      let lista = new Array();
      if (this.actividades) {
        this.actividades.forEach((element) => {
          var actividad = new Object();
          actividad.value = element.actividad_id;
          actividad.text = element.descripcion;
          lista.push(actividad);
        });
      }
      return lista;
    },
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth());
    const minDate = new Date(today);
    const maxDate = new Date(today);
    minDate.setDate(now.getDate() + 1);
    maxDate.setDate(now.getDate() + 365);
    return {
      selected: null,
      activity: null,
      subactivity: null,
      personas: null,
      startDate: null,
      periodo: null,
      min: minDate,
      max: maxDate,
      subactividadesSelect: null,
      actividadesSelect: null,
    };
  },
  created() {
    if (this.selectedActividad) {
      this.activity = this.selectedActividad;
    }
    if (this.selectedSubactividad) {
      this.subactivity = this.selectedSubactividad;
    }
    if (this.selectedPersonas) {
      this.personas = parseInt(this.selectedPersonas);
    }
    if (this.selectedInicio) {
      this.startDate = this.selectedInicio;
    }
    if (this.selectedPeriod) {
      this.periodo = parseInt(this.selectedPeriod);
    }
  },
  methods: {
    isNumber (evt) {
      const keysAllowed  = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      const keyPressed = evt.key;
      
      if (!keysAllowed.includes(keyPressed)) {
          evt.preventDefault()
      }
    },
    dateDisabled(ymd) {
      var count = 0;
      if (this.startDate && ymd) {
        count = Math.round(
          Math.abs((new Date(this.startDate) - new Date(ymd)) / 86400000)
        );
      }
      return (count + 1) % 30 != 0 || count > 366;
    },
    searchEvent(event) {
      event;
      console;
      this.$emit("searchEvent", {
        actividad: this.activity,
        subactividad: this.subactivity,
        personas: parseInt(this.personas),
        dias: this.dias,
        inicio: this.startDate,
        fin: this.endDate,
        periodo: this.periodo
      });
    },
  },
  watch: {
    activity: function () {
      let lista = new Array();
      if (this.subactividades) {
        this.subactividades.forEach((element) => {
          if (this.activity == element.actividades_id) {
            var subactividad = new Object();
            subactividad.value = element.id;
            subactividad.text = element.descripcion;
            lista.push(subactividad);
          }
        });
      }
      if(lista.length > 0) this.subactividadesSelect = lista;
      else this.subactividadesSelect = null;
      this.subactivity = null;
    },
  },
};
</script>


<style>
.b-calendar .btn.disabled {
  opacity: 0.45 !important;
}

.datepicker table tr td.active:active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active {
  background-color: green;
}

i {
  vertical-align: middle;
}
.style-chooser .vs__dropdown-toggle {
  padding: 0.375rem 0.75rem;
  height: calc(1.5em + 0.75rem + 2px);
}

.style-chooser input::placeholder {
  color: #999;
}

.style-chooser .vs__search,
.style-chooser .vs__selected,
.style-chooser .vs__actions,
.style-chooser .vs__selected-options,
.style-chooser .vs__search:focus,
.style-chooser .vs__selected:focus,
.style-chooser .vs__actions:focus {
  padding: 0;
  margin: 0;
}

.style-chooser .vs__selected {
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
}
</style>