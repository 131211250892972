<template>
  <div>
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <!-- email -->
      <b-form-group
        v-show="!codeSended"
        :state="emailState"
        label="Email:"
        label-for="email-input"
        invalid-feedback="Email es requerido"
        label-cols="12"
        label-cols-md="4"
        label-align-md="right"
      >
        <b-form-input
          id="email-input"
          type="email"
          v-model="email"
          :state="emailState"
          required
          ref="email"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        v-if="codeSended"
        :state="codeState"
        label="Codigo enviado a tu email"
        label-for="code-input"
        invalid-feedback="Codigo es requerido"
        label-cols="12"
        label-cols-md="12"
        label-align-md="center"
      >
        <b-form-input
          id="code-input"
          type="text"
          v-model="code"
          :state="codeState"
          required
          ref="code"
          placeholder="_ _ _ _ _ _ "
          maxlength="6"
          class="text-center mx-auto"
          autocomplete="off"
          style="width: 150px"
        ></b-form-input>
      </b-form-group>
      <hr />
      <!-- Contraseña -->
      <b-form-group
        v-show="code.length === 6"
        :state="passwordState"
        label="Nueva contraseña:"
        label-for="password-input"
        invalid-feedback="Contraseña es requerida"
        label-cols="12"
        label-cols-md="4"
        label-align-md="right"
      >
        <b-form-input
          id="password-input"
          type="password"
          v-model="password"
          :state="passwordState"
          ref="password"
          required
        ></b-form-input>
      </b-form-group>

      <!-- Repetir Contraseña -->
      <b-form-group
        :state="passwordRepetidoState"
        v-show="code.length === 6"
        label="Repetir contraseña:"
        label-for="password-repetido-input"
        invalid-feedback="Las contraseñas deben coincidir"
        label-cols="12"
        label-cols-md="4"
        label-align-md="right"
      >
        <b-form-input
          id="password-repetido-input"
          type="password"
          v-model="passwordRepetido"
          :state="passwordRepetidoState"
          ref="passwordRepetido"
          required
        ></b-form-input>
      </b-form-group>
    </form>

    <b-row align-h="between" class="px-3">
      <b-button @click="hideModal">Cancelar</b-button>
      <b-button @click="send">Enviar</b-button>
    </b-row>
  </div>
</template>

<script>
import Api from "@/client/api"

export default {
  name: "RecoverPassword",
  data() {
    return {
      email: "",
      emailState: null,
      code: "",
      codeState: null,
      codeSended: false,
      password: "",
      passwordState: null,
      passwordRepetido: "",
      passwordRepetidoState: null,
      isError: false,
    }
  },
  computed: {},
  methods: {
    checkFormValidity() {
      var valid = true

      if (this.codeSended) {
        if (this.$refs.code.checkValidity()) {
          this.codeState = true
        } else {
          this.codeState = false
          valid = false
        }
        if (this.$refs.code.checkValidity()) {
          this.codeState = true
        } else {
          this.codeState = false
          valid = false
        }

        if (this.$refs.password.checkValidity()) {
          this.passwordState = true
        } else {
          this.passwordState = false
          valid = false
        }

        if (this.$refs.passwordRepetido.checkValidity()) {
          if (this.password == this.passwordRepetido) {
            this.passwordRepetidoState = true
          } else {
            this.passwordRepetidoState = false
            valid = false
          }
        } else {
          this.passwordRepetidoState = false
          valid = false
        }
      } else {
        if (this.$refs.email.checkValidity()) {
          this.emailState = true
        } else {
          this.emailState = false
          valid = false
        }
      }

      return valid
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
    },
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-recover-password", "#btnShow")
    },
    send() {
      this.isError = false
      if (!this.checkFormValidity()) {
        return
      }
      if (this.codeSended) {
        Api.post("changepassword", {
          email: this.email,
          codigo: this.code,
          new_password: this.password,
          confirm_password: this.passwordRepetido,
        })
          .then((data) => {
            this.codeSended = data ? true : false
            setTimeout(() => {
              this.$nextTick(() => {
                this.hideModal()
              })
            }, 1000)
            if (data.message) {
              this.$bvToast.toast(data.message, {
                title: "Correcto",
                autoHideDelay: 5000,
                variant: "success",
              })
            }
          })
          .catch((error) => {
            console.log("Hubo un problema con la petición: " + error.message)
            this.$bvToast.toast(error.message, {
              title: "Error",
              autoHideDelay: 5000,
              variant: "danger",
            })
            this.isError = true
          })
      } else {
        Api.post("forgotpassword", { email: this.email })
          .then((data) => {
            this.codeSended = data ? true : false
          })
          .catch((error) => {
            console.log("Hubo un problema con la petición: " + error.message)
            this.$bvToast.toast(error.message, {
              title: "Error",
              autoHideDelay: 5000,
              variant: "danger",
            })
            this.isError = true
          })
      }
    },
  },
}
</script>