<template>
  <b-container>
    <b-row class="px-md-4">
      <b-col cols="12" class="mb-3">
        <b-card title='CONTRATA TU SEGURO'>
            <b-card-sub-title class="mb-3">
              {{ `Contrata tu seguro de accidentes personales desde casa` }}
            </b-card-sub-title>
          <step-header />
        </b-card>
      </b-col>
      <b-col cols="12">
        <router-view
          class="mb-3"
          :oferta-id="qofertaid"
          :personas="qpersonas"
          :fechainicio="qfechainicio"
          :fechafin="qfechafin"
        />
        <b-card class="mb-3" v-if="ofertas && ofertas.length != 0">
          <b-row>
            <b-col>
              <h3>
                <i class="fas fa-check-circle"></i>
                ¡Encontramos los siguientes resultados para vos!
              </h3>
            </b-col>
          </b-row>
        </b-card>
      </b-col>          
      
      <b-col class="mb-3" md="4" cols="12" v-show="$route.name == 'listar'">
        <Search
          :mini="true"
          :actividades="actividades"
          :subactividades="subactividades"
          nav-link
          @searchEvent="searchEvent"
          :selectedActividad="actividad"
          :selectedSubactividad="subactividad"
          :selectedPersonas="personas"
          :selectedInicio="inicio"
          :selectedPeriod="periodo"
        />
      </b-col>

      <b-col md="8" cols="12" v-show="$route.name == 'listar'">
        <template v-if="actualActividad">
          <template v-if="ofertas && ofertas.length != 0">
            <Oferta
              v-for="item in ofertas"
              :key="item.oferta_id"
              :oferta="item"
              :subactividad="actualSubactividad"
              :personas="actualPersonas"
              :fechainicio="actualInicio"
              :fechafin="actualFin"
            />
          </template>
          
          <template v-else>
            <b-card>
              <b-card-text>
                No hay resultados que coincidan con tu busqueda</b-card-text>
              </b-card>
          </template>
        </template>
        <template v-else>
          <b-card>
            <b-card-text>
              Debe realizar una busqueda
              </b-card-text>
            </b-card>
        </template>

        <template v-if="isError">
          <b-card title="Error">
            <b-card-text>
              Hubo un error al intentar obtener los datos
            </b-card-text>
          </b-card>
        </template>
      </b-col>
    </b-row>

    <b-row class="px-md-4 mt-2">
      <b-col cols="12" class="mb-3">
        <b-card>
          <div class="ma-3 d-flex">
            <img class="w-100" src="/mercadopago-largo.png" alt="" srcset="" />
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Search from "@/client/components/oferta/Search"
import StepHeader from "@/client/components/layout/StepHeader"
import Api from "@/client/api.js"
import Oferta from "@/client/components/oferta/Oferta"

export default {
  name: "Listar",
  props: {
    actividades: Array,
    subactividades: Array,
    actividad: Number,
    subactividad: Number,
    personas: Number,
    dias: Number,
    inicio: String,
    fin: String,
    periodo: Number,
    ofertaId: String,
    qofertaid: String,
    qfechafin: String,
    qfechainicio: String,
    qpersonas: String,
  },
  components: {
    Search,
    StepHeader,
    Oferta,
  },
  data() {
    return {
      ofertas: null,
      actualActividad: this.actividad,
      actualDias: this.dias,
      actualPersonas: this.personas,
      actualInicio: this.inicio,
      actualFin: this.fin,
      actualPeriodo: null,
      isError: false,
    }
  },
  mounted() {
    this.loadOfertas();
  },
  methods: {
    loadOfertas() {
      if (this.actualActividad) {
        let subact = this.actualSubactividad ? this.actualSubactividad : 0;
        Api.getOfertaActividad(this.actualActividad, subact)
          .then((ofertas) => {
            this.ofertas = ofertas
            this.isError = false
          })
          .catch((err) => {
            console.log(err)
            this.isError = true
          })
      }
    },
    searchEvent(search) {
      this.actualActividad = search.actividad
      this.actualSubactividad = search.subactividad
      this.actualPersonas = search.personas
      this.actualDias = search.dias
      this.actualInicio = search.inicio
      this.actualFin = search.fin
      this.actualPeriodo = search.periodo
      this.loadOfertas()
    },
  },
}
</script>