export default [
  { value: null, text: "Provincia", disabled: true },
  { value: "Buenos Aires", text: "Buenos Aires" },
  { value: "Catamarca", text: "Catamarca" },
  {
    value: "Ciudad de Buenos Aires​​",
    text: "Ciudad de Buenos Aires​​",
  },
  { value: "Chaco", text: "Chaco" },
  { value: "Chubut", text: " Chubut" },
  { value: "Córdoba", text: "Córdoba" },
  { value: "Corrientes", text: "Corrientes" },
  { value: "Entre Ríos", text: "Entre Ríos" },
  { value: "Formosa", text: "Formosa" },
  { value: "Jujuy", text: "Jujuy" },
  { value: "La Pampa", text: "La Pampa" },
  { value: "La Rioja", text: "La Rioja" },
  { value: "Mendoza", text: "Mendoza" },
  { value: "Misiones", text: "Misiones" },
  { value: "Neuquén", text: "Neuquén" },
  { value: "Río Negro", text: "Río Negro" },
  { value: "Salta", text: "Salta" },
  { value: "San Juan", text: "San Juan" },
  { value: "San Luis", text: "San Luis" },
  { value: "Santa Cruz", text: "Santa Cruz" },
  { value: "Santa Fe", text: "Santa Fe" },
  { value: "Santiago del Estero", text: "Santiago del Estero" },
  { value: "Tierra del Fuego", text: " Tierra del Fuego" },
  { value: "Tucumán", text: "Tucumán" },
]