<template>
  <div id="app">
    <br-header :actividades="actividades" :subactividades="subactividades" :user="user" />
    <router-view
      class=""
      :actividades="actividades"
      :subactividades="subactividades"
      :user="user"
      @login="loadUser($event)"
    />
    <br-footer />
    <b-modal id="modal-register" title="Registrarse" hide-footer
      ><register></register>
    </b-modal>

    <b-modal id="modal-login" title="Iniciar Sesión" hide-footer
      ><login @login="loadUser($event)" :user="user"></login>
    </b-modal>

    <b-modal
      id="modal-recover-password"
      title="Recuperar contraseña"
      hide-footer
      ><recover-password></recover-password>
    </b-modal>
  </div>
</template>

<script>
import "whatwg-fetch"

import BrHeader from "@/client/components/layout/BrHeader"
import BrFooter from "@/client/components/layout/BrFooter"
import Register from "@/client/components/user/Register"
import Login from "@/client/components/user/Login"
import RecoverPassword from "@/client/components/user/RecoverPassword"

import Api from "@/client/api.js"

import Vue from "vue"
import { Datetime } from "vue-datetime"
import "vue-datetime/dist/vue-datetime.css"
Vue.use(Datetime)

import { Settings } from "luxon"
Settings.defaultLocale = "es-AR"

export default {
  name: "App",
  async created() {
    this.loadUser()
    await this.loadActividades()
    await this.loadSubactividades()
  },
  data() {
    return {
      actividades: [],
      subactividades: [],
      user: {
        login: false,
        admin: false,
        name: "",
        data: {},
      },
    }
  },
  components: {
    BrHeader,
    BrFooter,
    Register,
    Login,
    RecoverPassword,
  },
  mounted() {
    document.getElementById("overlay").style.display = "none"
  },
  methods: {
    loadUser(token = false) {
      Api.user(token).then((userLoad) => {
        if (userLoad) {
          this.user.login = true
          if (typeof userLoad.admin !== "undefined") {
            this.user.admin = userLoad.admin
          }
          this.$set(this.user, "data", userLoad)
        } else {
          this.user.login = false
        }
      })
    },
    loadActividades() {
      Api.getActividades().then(
        (actividades) => (this.actividades = actividades)
      )
    },
    loadSubactividades() {
      Api.getSubactividades().then(
        (subactividades) => (this.subactividades = subactividades)
      )
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f6f6f6;
}

.btn-blue,
.btn-blue:focus {
  background-color: #27337a !important;
}
.btn-blue.disabled {
  background-color: #999 !important;
}

.btn-blue:hover {
  background-color: #2438aa !important;
}

.b-toast-danger .toast {
  background-color: #f73434ee !important;
  color: #fae7e7 !important;
}
.b-toast-danger .toast .toast-header {
  color: #fae7e7 !important;
  background-color: #ff2323d0 !important;
}
.b-toast-success .toast {
  background-color: #24ff69d3 !important;
  color: #0b5d24 !important;
}
.b-toast-success .toast .toast-header {
  color: #0b5d24 !important;
  background-color: #24ff69d3 !important;
  border-bottom: 1px solid #0b5d24 !important;
}
.toast {
  flex-basis: 350px !important;
  max-width: 350px !important;
  font-size: 0.875rem !important;
  background-color: rgba(255, 255, 255, 0.9) !important;
  background-clip: padding-box !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 10%) !important;
  border-radius: 0rem !important;
}

.b-toast-danger .toast .toast-header {
  color: #ffe3e3 !important;
  background-color: #ff2323 !important;
}
.toast-header {
  border-bottom: 1px solid rgb(255, 255, 255) !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
</style>
