<template>
  <div id="oferta" v-if="oferta">
    <b-container class="mb-3 bg-white rounded border p-3">
      <b-row>
        <b-col class="clearfix text-left" md="7" order="0">
          <div class="btn-volver float-left pr-3 pt-3" @click="volver()">
            <i class="fas fa-arrow-alt-circle-left fa-2x"></i>
          </div>

          <h2 class="pt-3">
            {{
              oferta && oferta.aseguradora[0].descripcion
                ? oferta.aseguradora[0].descripcion
                : "No titulo"
            }}
          </h2>
          <p class="pt-4 pl-3 text-muted h5">
            {{ oferta.detalle ? oferta.detalle : "No detalle" }}
          </p>
          <b-card-text> </b-card-text>
        </b-col>
        <b-col md="5">
          <b-img
            class="mb-3"
            :src="
              oferta.aseguradora[0].logo
                ? oferta.aseguradora[0].logo
                : 'https://via.placeholder.com/150C'
            "
            fluid
            alt="Fluid image"
          ></b-img>

          <p class="text-left text-muted">
            <strong>Precio base</strong>: ${{ oferta.precio_base }} (por persona
            por dia)
          </p>
          <p class="text-left text-muted">
            <strong>Periodo:</strong> desde {{ this.fechainicio }} a
            {{ this.fechafin }}
          </p>
        </b-col>
      </b-row>
      <b-row align-v="center">
        <b-col md="7">
          <div
            class="d-flex justify-content-around grounded bg-light px-2 py-2 align-items-stretch h5"
            style="width: 90%; margin: 20px auto"
          >
            <strong>Total:</strong>
            <span class=""
              >${{ total }} | {{ personas ? personas : 1 }}
              <i class="fas fa-male"></i> | {{ dias ? dias : 30 }}
              <i class="fas fa-calendar-day"></i
            ></span>
          </div>
        </b-col>
        <b-col md="5">
          <b-button class="m-2 btn-oferta" size="lg" @click="irAContratar()">
            Contratar <i class="fas fa-file-signature"></i>
          </b-button>
        </b-col>
      </b-row>
    </b-container>
    <b-row class="">
      <b-col cols="12" md="7" class="" order="1" order-md="0"
        ><template v-for="atributo in oferta.atributos">
          <div
            :key="atributo.atributo_id"
            class="text-left bg-white mb-3 border p-4"
            v-if="atributo.clausula ? atributo.clausula : false"
          >
            <h4 class="text-capitalize">{{ atributo.descripcion }}</h4>
            <p class="text-muted" style="white-space: pre-wrap">
              {{ atributo.valor }}
            </p>
          </div>
        </template>
        <!-- <div class="text-center bg-white mb-3 border p-3">
          <b-button
            class="m-2 btn-oferta"
            target="_blank"
            :href="oferta.clausulas_pdf"
            rel="noopener noreferrer"
            ><i class="fas fa-file-pdf"></i> &nbsp;Descargar el PDF
          </b-button>
        </div> -->
      </b-col>
      <b-col cols="12" md="5" order="0" order-md="1">
        <h5 class="text-left">Condiciones Generales</h5>
        <b-table
          class="bg-white text-muted text-left"
          thead-class="d-none"
          hover
          :items="condiciones"
          :fields="fields"
        >
          <!-- A virtual composite column -->
          <template #cell(descripcion)="data">
            <b class="font-weight-bold text-capitalize">{{ data.value }}</b>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Api from "@/client/api"
export default {
  name: "FullOferta",
  props: {
    ofertaId: {
      types: [Number, String],
    },
    personas: {
      types: [Number, String],
    },
    fechainicio: {
      types: String,
    },
    fechafin: {
      types: String,
    },
  },
  computed: {
    condiciones: function () {
      let condiciones = []
      this.oferta.atributos.forEach((element) => {
        if (!element.clausula) {
          condiciones.push({
            descripcion: element.descripcion,
            valor: element.valor,
          })
        }
      })
      return condiciones
    },
    total: function () {
      if (this.personas > 0 && this.dias > 0) {
        return (
          Math.round(
            this.oferta.precio_base * this.dias * this.personas * 100
          ) / 100
        ).toFixed(2)
      }
      return (this.oferta.precio_base * 30).toFixed(2)
    },

    dias: function () {
      var count = 0
      if (this.fechainicio && this.fechafin) {
        count = Math.round(
          Math.abs(
            (new Date(this.fechainicio) - new Date(this.fechafin)) / 86400000
          )
        )
        return count + 1
      }
      return null
    },
  },
  data() {
    return {
      oferta: null,
      isError: false,
      fields: [{ key: "descripcion" }, { key: "valor" }],
    }
  },
  created() {
    this.loadOferta()
  },
  methods: {
    irAContratar() {
      this.$router
        .push({
          path: `/contratar-${this.oferta.oferta_id}/${this.fechainicio}/${this.fechafin}`,
        })
        .catch((err) => {
          err
        })
    },
    loadOferta() {
      this.isError = false

      if (this.ofertaId) {
        Api.getOferta(this.ofertaId)
          .then((oferta) => {
            this.oferta = oferta
            this.isError = false
          })
          .catch((err) => {
            console.log(err)
            this.isError = true
          })
      }
    },
    volver() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped>
.btn-volver {
  cursor: pointer;
}
.btn-volver:hover {
  cursor: pointer;
  color: #27337a;
}
.btn-oferta a {
  color: #fefefe !important;
}
.btn-oferta {
  background-color: #2c3e50;
  border-color: #27337a;
}
.btn-oferta:hover {
  color: #ffffff !important;
  background-color: #27337a;
  border-color: #27337a;
}
</style>