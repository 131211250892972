<template>
  <div>
    <b-container>
      <b-row class="px-md-4">
        <b-col>
          <Search class="mb-4" :actividades="actividades" :subactividades="subactividades" />
        </b-col>
      </b-row>
    </b-container>

    <b-container>
      <a href="https://wa.me/5492645674993" target="_blanc">
      <b-row class="px-2 px-md-4 mt-2" align-v="stretch">
        <b-col class="col-12 mb-4">
          <b-card class="h-100" style="background-color: #128c7e; color: white">
            <b-row class="justify-content-center">
              <b-col class="col-2">
                  <i class="fab fa-whatsapp fa-6x"></i>
              </b-col>
              <b-col class="col-8" style="padding: 20px 0">
                <h4>¿Necesitas atención Personalizada? contáctanos!</h4>
                
                  <h4 style="color: #dcf8c6"><strong>264-567-4993</strong></h4>
                
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      </a>
    </b-container>

    <!--<b-container>
      <carrousel />
    </b-container>-->

    <!--<b-container>
      <b-row class="px-2 px-md-4 mt-4" align-v="stretch">
        <b-col class="col-12 col-md-4 mb-4">
          <b-card title="¿Sos Profesional independiente?" class="h-100">
            <b-card-sub-title class="mt-2">
              <p>El Seguro de Accidentes Personales es lo que necesitas</p>
              <p>
                Modalidad prestacional para que no tengas que pensar en los gastos
              </p></b-card-sub-title
            >
          </b-card>
        </b-col>
        <b-col class="col-12 col-md-4 mb-4">
          <b-card title="Trabajos a Corto Plazo o puntuales?" class="h-100">
            <b-card-sub-title class="mt-2"
              ><p>
                Especialmente diseñada para particulares que contraten tareas
                puntuales o quienes se dediquen a manejar servicios con personal eventual.
              </p></b-card-sub-title
            >
          </b-card>
        </b-col>
        <b-col class="col-12 col-md-4 mb-4">
          <b-card class="h-100" style="background-color: #009ad2; color: white">
            <b-card-text
              class="h-100 d-flex justify-content-center align-items-center"
              ><h4>
                Consulte los planes y presupuestos que brindamos para nuestro
                servicio especializado en riesgos del trabajo.
              </h4></b-card-text
            >
          </b-card>
        </b-col>
      </b-row>
    </b-container>-->

  </div>
</template>

<script>
//import Carrousel from "@/client/components/home/Carrousel"
import Search from "@/client/components/oferta/Search"
import Api from "@/client/api.js"

export default {
  name: "Home",
  props: {
    actividades: Array,
    subactividades: Array,
    token: String,
  },
  components: {
    //Carrousel,
    Search,
  },
  mounted() {
    if (this.token) {
      Api.get(`email/verify/${this.token}`)
        .then((data) => {
          this.$bvToast.toast(data.message, {
            title: "Correcto",
            autoHideDelay: 5000,
            variant: "success",
          })
          return data
        })
        .catch((error) => {
          console.log("Hubo un problema con la petición: " + error.message)
          this.$bvToast.toast(error.message, {
            title: "Error",
            autoHideDelay: 5000,
            variant: "danger",
          })
        })
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
