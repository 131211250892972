<template>
  <div id="step-card" class="mb-1 py-4">
    <div
      id="step-header"
      class="d-flex mx-auto position-header justify-content-around align-items-center"
    >
      <b-tooltip target="buscar" triggers="hover">
        Paso 1: Buscar actividad
      </b-tooltip>
      <span
        id="buscar"
        class="fa-stack fa-1x circle mb-3"
        :class="{
          active: actualPath('/'),
        }"
      >
        <i class="fas fa-circle fa-stack-2x"></i>
        <i class="fa fa-search fa-stack-1x fa-inverse"></i>
      </span>
      <!-- <div class="arrow-circle">
        <i class="fa fa-arrow-circle-right"></i>
      </div> -->
      <b-tooltip target="listar" triggers="hover">
        Paso 2: Seleccionar oferta
      </b-tooltip>
      <span
        id="listar"
        class="fa-stack fa-1x circle mt-3"
        :class="{
          active: actualPath('/listar'),
        }"
      >
        <i class="fas fa-circle fa-stack-2x"></i>
        <i class="fa fa-list fa-stack-1x fa-inverse"></i>
      </span>

      <!-- <div class="arrow-circle">
        <i class="fa fa-arrow-circle-right"></i>
      </div> -->
      <b-tooltip target="completar" triggers="hover">
        Paso 3: Completar Formulario</b-tooltip
      >
      <span
        id="completar"
        class="fa-stack fa-1x circle mb-3"
        :class="{
          active: $route.name == 'Contratar',
        }"
      >
        <i class="fas fa-circle fa-stack-2x"></i>
        <i class="fa fa-edit fa-stack-1x fa-inverse"></i>
      </span>
      <!-- <div class="arrow-circle">
        <i class="fa fa-arrow-circle-right"></i>
      </div> -->
      <b-tooltip target="contratar" triggers="hover">
        Paso 4: Contratar y pagar</b-tooltip
      >
      <span id="contratar" class="fa-stack fa-1x circle">
        <i class="fas fa-circle fa-stack-2x"></i>
        <i class="fa fa-credit-card fa-stack-1x fa-inverse"></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepHeader",
  methods: {
    actualPath(value) {
      if (this.$route.path == value) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style  scoped>
.circle {
  flex-grow: 2;
}

.arrow-circle {
  border-radius: 80px;
  flex-grow: 1;
}

.circle:hover {
  color: #27337a;
  cursor: pointer;
}
.circle.active {
  color: #009ad2;
}
.circle.active:hover {
  color: #27337a;
}
.position-header {
  max-width: 500px;
}
#step-card {
  background: url(/img/trace.png) white no-repeat center center;
  background-size: 400px auto;
}
@media (max-width: 576px) {
  #step-card {
    background-size: 80% auto;
  }
}

@media (max-width: 576px) {
  #step-header {
    font-size: 0.7rem;
  }
}
</style>