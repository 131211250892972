import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/client/views/Home'
import Error from '@/client/views/Error'
import Listar from '@/client/views/Listar'
import Contratar from '@/client/views/Contratar'
import Poliza from '@/client/views/cliente/Poliza'

import Cobertura from '@/client/views/cliente/Cobertura'

import FullOferta from '@/client/components/oferta/FullOferta'

Vue.use(Router)

export default new Router({
  mode: 'history',

  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      props: true
    },
    {
      path: '/coberturas',
      name: 'Mis coberturas',
      component: Cobertura,
      props: true
    },
    {
      path: '/listar/:actividadId?',
      name: 'listar',
      component: Listar,
      props: true,
      children: [{
        name:'fullOferta',
        path: 'oferta-:qofertaid(\\d+)/personas-:qpersonas(\\d+)/:qfechainicio/:qfechafin',
        component: FullOferta
      }]

    },
    {
      path: '/contratar-:ofertaId(\\d+)?',
      props: true,
      name: 'Contratar',
      component: Contratar
    },
    {
      path: '/contratar-:ofertaId/:fechainicio/:fechafin',
      props: true,
      name: 'Contratar',
      component: Contratar
    },
    {
      path: '/poliza/:id?',
      props: true,
      name: 'Poliza',
      component: Poliza,
    },
    {
      path: '/api/email/verify/:token',
      props: true,
      name: 'Verify',
      component: Home,
    },
    {
      path: '*',
      name: 'error',
      component: Error
    }
  ],
  scrollBehavior (to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }
  }
})
