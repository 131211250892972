<template>
  <b-card class="px-3">
    <b-row>
      <b-col lg="7" order="1" order-md="0">
        <b-card-title class="text-md-left pb-3">{{
          poliza.aseguradora_nombre
        }}</b-card-title>
        <b-card-sub-title class="text-md-left pb-3"
          >{{ oferta.detalle }}
        </b-card-sub-title>
        <p class="text-md-left text-muted">
          <b>Nº de asegudados: </b>{{ poliza.cantidad_asegurados }}
        </p>
        <p class="text-md-left text-muted">
          <b>Periodo: </b>{{ poliza.inicio_vigencia }} -
          {{ poliza.fin_vigencia }}
        </p>

        <p class="text-md-left text-muted">
          <b>Estado: </b
          >{{
            poliza.estado == 0
              ? "pendiente de pago"
              : poliza.estado == 1
              ? "pagada"
              : poliza.estado == 3
              ? "Esperando Confirmacion de pago"
              : "Plazo de pago vencido"
          }}
        </p>
        <div class="d-flex flex-nowrap justify-content-center">
          <b-button
            class="my-2 btn-oferta w-100"
            @click="irAPoliza()"
            style="white-space: nowrap"
            >Ver Tramite <i class="fas fa-angle-double-right"></i
          ></b-button>
        </div>
      </b-col>

      <b-col lg="5" order="0" order-md="1">
        <b-card-img
          style="width: auto; max-height: 150px"
          class="text-cented-md-block"
          :src="poliza.aseguradora_logo"
        />
      </b-col>
    </b-row>

    <b-row align-v="center">
      <b-col> </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "poliza",
  props: { poliza: Object },
  async created() {},
  methods: {
    irAPoliza() {
      this.$router
        .push({
          path: `/poliza/${this.poliza.poliza_id}`,
        })
        .catch((err) => {
          err;
        });
    },
  },
  computed: {
    total: function() {
      return (
        Math.round(
          this.poliza.precio_base *
            this.dias *
            this.poliza.cantidad_asegurados *
            100
        ) / 100
      ).toFixed(2);
    },
    dias: function() {
      var count = 0;
      if (this.poliza.inicio_vigencia && this.poliza.fin_vigencia) {
        count = Math.round(
          Math.abs(
            (new Date(this.poliza.inicio_vigencia) -
              new Date(this.poliza.fin_vigencia)) /
              86400000
          )
        );
        return count + 1;
      }
      return null;
    },
  },
  data() {
    return {
      oferta: Object,
    };
  },
};
</script>

<style scoped>
.btn-oferta {
  background-color: #2c3e50;
  border-color: #27337a;
}
.btn-oferta:hover {
  color: #ffffff !important;
  background-color: #27337a;
  border-color: #27337a;
}
</style>
