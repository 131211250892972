// import Vue from 'vue';
// const vm = new Vue()

const url = process.env.VUE_APP_API
let token = JSON.parse(localStorage.getItem("token"))
function headers() {
  if (token) {
    return {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: token.token_type + " " + token.access_token,
    }
  } else {
    return {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    }
  }
}
async function get(path) {
  return fetch(`${url}/${path}`, {
    method: "GET",
    cache: "no-cache",
    headers: headers(),
  })
    .then((res) => {
      if (res.ok) {
        return res.json()
      } else {
        if (res.status == 403) {
          throw Error("No tienes acceso a esta seccion")
        }
        return res.json().then((res) => {
          if (res.message) {
            throw Error(res.message)
          }
          if (res.msg) {
            throw Error(res.msg)
          }
          if (res) {
            throw Error(res)
          } else {
            throw Error("Error desconocido")
          }
        })
      }
    })
    .then((res) => {
      return res
    })
}

async function post(path, payload) {
  return fetch(`${url}/${path}`, {
    method: "POST",
    cache: "no-cache",
    headers: headers(),
    body: JSON.stringify(payload),
  })
    .then((res) => {
      if (res.ok) {
        return res.json()
      } else {
        if (res.status == 403) {
          throw Error("No tienes acceso a esta seccion")
        }
        return res.json().then((res) => {
          if (res.message) {
            throw Error(res.message)
          }
          if (res.msg) {
            throw Error(res.msg)
          }
          if (res) {
            throw Error(res)
          } else {
            throw Error("Error desconocido")
          }
        })
      }
    })
    .then((res) => {
      return res
    })
}

async function getPolizaFile(id, filename) {
  if (token && token.access_token) {
    return fetch(`${url}/cliente/poliza/${id}/comprobante`, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: token.token_type + " " + token.access_token,
      },
    })
      .then((res) => {
        if (res.ok) {
          if (res.headers.get("content-type") == "application/json") {
            return res.json().then((res) => {
              throw Error(res.message)
            })
          } else {
            return res.blob()
          }
        } else {
          throw Error("Poliza no encontrada")
        }
      })
      .then((blob) => {
        var a = document.createElement("a")
        document.body.appendChild(a)
        a.style = "display: none"
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = filename + ".pdf"
        a.click()
        window.URL.revokeObjectURL(url)
      })
  }
}

async function getAseguradoras(id) {
  if (token && token.access_token) {
    return fetch(
      typeof id === "undefined" ? `${url}/aseguradoras` : `${url}/aseguradoras`,
      {
        method: "GET",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: token.token_type + " " + token.access_token,
        },
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json()
        } else {
          if (res.status == 403) {
            throw Error("No tienes acceso a esta seccion")
          }
          return res.json()
        }
      })
      .then((res) => {
        return res
      })
  }
}

async function getPoliza(id) {
  return fetch(
    typeof id === "undefined"
      ? `${url}/cliente/poliza`
      : `${url}/cliente/poliza/${id}`,
    {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: token.token_type + " " + token.access_token,
      },
    }
  )
    .then((res) => {
      if (res.ok) {
        return res.json()
      } else {
        return res.json()
      }
    })
    .then((res) => {
      return res
    })
}

async function poliza(data) {
  return fetch(`${url}/cliente/poliza`, {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: token.token_type + " " + token.access_token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (res.ok) {
        return res.json()
      } else {
        return res.json().then((res) => {
          throw Error(res.message)
        })
      }
    })
    .then((res) => {
      return res
    })
}

async function user(tokenNew = false) {
  if ((token && token.access_token) || tokenNew) {
    const headers = new Headers()
    headers.append(
      "Authorization",
      (tokenNew ? "Bearer" : token.token_type) +
      " " +
      (tokenNew ? tokenNew : token.access_token)
    )
    headers.append("X-Requested-With", "XMLHttpRequest")

    return fetch(`${url}/user`, {
      method: "GET",
      headers: headers,
    })
      .then((res) => {
        if (res.ok) {
          return res.json()
        } else {
          if (res.status == 401) {
            localStorage.removeItem("token")
          }
          throw Error("No autorizado")
        }
      })
      .then((res) => {
        return res
      })
      .catch(function (error) {
        console.log("Hubo un problema con la petición: " + error.message)
        throw Error(error.message)
      })
  }
}

async function login(data) {
  return fetch(`${url}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (res.ok) {
        return res.json()
      } else {
        return res.json()
      }
    })
    .then((res) => {
      if (res.access_token) {
        localStorage.setItem("token", JSON.stringify(res))
        token = res
        document.cookie =
          "XSRF-TOKEN=" +
          res.access_token +
          "; expires=" +
          new Date(res.expires_at).toUTCString()
      }
      return res
    })
    .catch(function (error) {
      console.log("Hubo un problema con la petición: " + error.message)
    })
}

async function logout() {
  if (token && token.access_token) {
    const headers = new Headers()
    headers.append("Authorization", token.token_type + " " + token.access_token)
    headers.append("X-Requested-With", "XMLHttpRequest")
    return fetch(`${url}/logout`, {
      method: "GET",
      headers: headers,
    })
      .then((res) => res.json())
      .then((res) => {
        localStorage.removeItem("token")
        return res
      })
      .catch(function (error) {
        console.log("Hubo un problema con la petición: " + error.message)
      })
  }
}

async function registrarse(data) {
  return fetch(`${url}/registrarse`, {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (res.ok) {
        return res.json()
      } else {
        // if(res.status == 422){
        //   vm.$bvToast.toast(`Error`, {
        //     title: 'Error',
        //     autoHideDelay: 5000,
        //     variant:"danger"
        //   })
        // }
        return res.json()
      }
    })
    .then((res) => {
      return res
    })
    .catch(function (error) {
      console.log("Hubo un problema con la petición: " + error.message)
    })
}

async function getOfertaActividad(aid, sid) {
  return fetch(`${url}/oferta/actividad/${aid}/subactividad/${sid}`, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  })
    .then((res) => {
      if (!res.ok) throw Error(res.status)
      return res.json()
    })
    .then((res) => res)
}

async function getActividades() {
  return fetch(`${url}/actividades`, {
    method: "GET",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  })
    .then((res) => res.json())
    .then((res) => res)
}

async function getSubactividades() {
  return fetch(`${url}/subactividades`, {
    method: "GET",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  })
    .then((res) => res.json())
    .then((res) => res)
}

async function getOferta(id) {
  return fetch(`${url}/oferta/${id}`, {
    method: "GET",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  })
    .then((res) => {
      if (!res.ok) throw Error(res.status)
      return res.json()
    })
    .then((res) => res)
}

async function getOfertaAll(id) {
  return fetch(`${url}/oferta/${id}?all=1`, {
    method: "GET",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  })
    .then((res) => {
      if (!res.ok) throw Error(res.status)
      return res.json()
    })
    .then((res) => res)
}

export default {
  getOfertaActividad,
  getActividades,
  getSubactividades,
  getOferta,
  getOfertaAll,
  registrarse,
  login,
  user,
  logout,
  poliza,
  getPoliza,
  getAseguradoras,
  getPolizaFile,
  get,
  post,
}
