<template>
  <nav
    class="yamm navbar navbar-expand-lg navbar-light bg-white border-bottom box-shadow mb-3"
  >
    <div class="container w-100">
      <a class="navbar-brand" href="#"
        ><div class="wrap-logo">
          <a href="/"
            ><img
              class="logo-ba d-none d-md-block"
              alt="logo"
              src="@/client/assets/vertical-color.png"
          /></a>
          <a href="/"
            ><img
              class="logo-ba d-md-none"
              alt="logo"
              src="@/client/assets/logo.png"
          /></a></div
      ></a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbar-collapse-1"
        aria-controls="navbar-collapse-1"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse" id="navbar-collapse-1">
        <ul class="navbar-nav mr-auto">
          <router-link
            :to="{ name: 'home' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              class="nav-item dropdown yamm-fw"
              :class="[
                isActive && 'router-link-active',
                isExactActive && 'router-link-exact-active',
              ]"
            >
              <a
                :href="href"
                @click="navigate"
                class="nav-link"
                data-toggle="dropdown"
                >Inicio<span class="line">|</span></a
              >
            </li>
          </router-link>

          <li class="nav-item dropdown yamm-fw">
            <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown"
              >Lista de actividades <span class="line">|</span></a
            >

            <div class="dropdown-menu p-0 mt-0">
              <!-- Content container to add padding-->
              <div class="yamm-content">
                <div class="row">
                  <div class="col-sm-2">
                    <h5>Actividades</h5>
                  </div>
                </div>
                <div class="row">
                  <div
                    v-for="(item, index) in actividadesSplit"
                    :key="index"
                    class="col-sm-2"
                  >
                    <ul
                      v-for="subitem in item"
                      :key="subitem.actividad_id"
                      class="col-sm-2"
                    >
                      <li>
                        <a :href="'/listar/' + subitem.actividad_id">{{
                          subitem.descripcion
                        }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <router-link
            :to="{ name: 'Mis coberturas' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              class="nav-item dropdown yamm-fw"
              :class="[
                isActive && 'router-link-active',
                isExactActive && 'router-link-exact-active',
              ]"
            >
              <a
                :href="href"
                @click="navigate"
                class="nav-link"
                data-toggle="dropdown"
                >Mis coberturas</a
              >
            </li>
          </router-link>
          <router-link
            v-if="user.admin"
            :to="{ name: 'Admin home' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              class="nav-item dropdown yamm-fw"
              :class="[
                isActive && 'router-link-active',
                isExactActive && 'router-link-exact-active',
              ]"
            >
              <a
                :href="href"
                @click="navigate"
                class="nav-link"
                data-toggle="dropdown"
                >Administración</a
              >
            </li>
          </router-link>
        </ul>

        <ul class="navbar-nav ml-auto">
          <li class="nav-item dropdown yamm-fw">
            <a
              @click="showLogin()"
              v-if="!user.login"
              class="nav-link btn btn-header mx-2"
              href="#"
              data-toggle="dropdown"
              >Iniciar sesion</a
            >
            <span v-else>
              Hola {{ user.data.name }}
              <a
                href=""
                class="logout d-inline-block mx-2"
                v-on:click.prevent="logout()"
              >
                <i class="fas fa-sign-out-alt"></i>Salir</a
              ></span
            >
            <a
              @click="showRegister()"
              v-if="!user.login"
              class="nav-link btn btn-header mx-2"
              href="#"
              data-toggle="dropdown"
              >Registrarme</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import Api from "@/client/api.js"

export default {
  name: "BhHeader",
  components: {},
  props: {
    actividades: Array,
    user: Object
  },
  data() {
    return {
      modalLoginShow: false
    }
  },
  methods: {
    showLogin() {
      this.$root.$emit("bv::show::modal", "modal-login", "#btnShow")
    },
    showRegister() {
      this.$root.$emit("bv::show::modal", "modal-register", "#btnShow")
    },
    logout() {
      Api.logout()
      this.user.login = false
      this.user.admin = false
      this.$router
        .push({
          name: "home"
        })
        .catch(err => {
          err
        })
    }
  },
  computed: {
    actividadesSplit: function() {
      var size = 4
      var arrayOfArrays = []
      for (var i = 0; i < this.actividades.length; i += size) {
        arrayOfArrays.push(this.actividades.slice(i, i + size))
      }
      return arrayOfArrays
    }
  }
}
</script>

<style scoped>
.logout {
  color: #2b43c7;
}
.logout:hover {
  cursor: pointer;
}
@media (max-width: 991px) {
  .dropdown-toggle::after {
    display: none;
  }
}

@media (min-width: 992px) {
  .dropdown-toggle::after {
    position: absolute;
    margin-left: 0.1em !important;
    top: 44px;
  }
}

.line {
  display: none;
  position: absolute;
  padding-left: 1em;
  color: #ccc;
}
@media (min-width: 992px) {
  .line {
    display: inline-block;
  }
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #27337a;
  white-space: nowrap;
}

li a {
  white-space: break-spaces;
}
.wrap-logo {
  height: 80px;
}
.logo-ba {
  height: 100%;
  width: auto;
}
.btn-header {
  color: #27337a !important;
  border-color: #009ad2;
  padding: 0.5em !important;
  margin: 0.5em;
  display: inline-block !important;
}

@media (max-width: 992px) {
  .btn-header {
    display: block !important;
  }
}
.btn-header:hover {
  color: #ffffff !important;
  background-color: #27337a;
  border-color: #27337a;
}
.navbar {
  padding: 0;
}

.andino {
  position: absolute;
  z-index: 10001;
}

.andino ul li a {
  color: #999999;
  font-size: 12px;
  display: block;
  white-space: normal;
}
</style>

<style>
@media (min-width: 968px) {
  .navbar-nav .dropdown:hover > .dropdown-menu {
    display: block;
  }
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}
@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu > li > a,
  .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 5px 15px 5px 25px;
  }
  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 20px;
  }
  .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-nav .open .dropdown-menu > li > a:focus {
    background-image: none;
  }
}
@media (min-width: 992px) {
  .navbar-nav {
    float: left;
    margin: 0;
  }
  .navbar-nav > li {
    float: left;
  }
  .navbar-nav > li > a {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .navbar-nav.navbar-right:last-child {
    margin-right: -15px;
  }
}
.navbar .yamm-content h5 {
  color: #27337a !important;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  padding-bottom: 10px;
  border-bottom: dotted 1px #009ad2;
}
@media (max-width: 767px) {
  .navbar .yamm-content h5 {
    font-size: 14px;
  }
}
.navbar .yamm-content ul {
  margin: 0;
  padding: 0;
}
.navbar .yamm-content ul li {
  list-style-type: none;
  border-bottom: solid 1px #eeeeee;
  text-transform: uppercase;
  padding: 4px 0;
}
.navbar .yamm-content ul li a {
  color: #999999;
  font-size: 12px;
  display: block;
}
.navbar .yamm-content ul li a:hover {
  color: #e667ba;
  text-decoration: none;
}
.navbar .yamm-content .banner {
  margin-bottom: 10px;
}
.navbar ul.nav > li > a {
  text-transform: uppercase;
  font-weight: bold;
}
.navbar #search {
  clear: both;
  border-top: solid 1px #9adacd;
  text-align: right;
}
.navbar #search .navbar-form {
  float: right;
  width: 500px;
}
.navbar #search .navbar-form .input-group {
  display: table;
}
.navbar #search .navbar-form .input-group .input-group-btn {
  white-space: nowrap;
  width: 1%;
}
.navbar #search .navbar-form .input-group .form-control {
  width: 100%;
}
@media (max-width: 768px) {
  .navbar #search .navbar-form {
    float: none;
    width: auto;
  }
}
.navbar #basket-overview {
  padding: 0;
}
</style>
