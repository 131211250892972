<template>
  <b-container>
    <b-row>
      <b-col cols="12" class="mb-3"><step-header /></b-col>

      <b-col cols="12" md="8">
        <div class="oferta bg-white rounded p-3 mb-3">
          <b-row v-if="oferta">
              <b-img
                class="ml-3 logoAseguradora"
                :src="
                  oferta.aseguradora[0].logo
                    ? oferta.aseguradora[0].logo
                    : 'https://via.placeholder.com/150C'
                "
                fluid
                alt="Fluid image"
              >
              </b-img >
            <b-col class="text-left">
              <h3>
                {{ oferta.aseguradora[0].descripcion }}
              </h3>
              <p>{{ oferta.detalle }}</p>
            </b-col>
            
          </b-row>
          <b-row align-h="center">
            <b-col class="col-12 col-md-5 mb-2">
              <label for="start-date">¿Desde que fecha?</label>
              <b-form-datepicker
                v-on:input="endDate == null"
                id="start-date"
                v-model="startDate"
                class="mb-2"
                locale="es-AR"
                :min="min"
                :max="max"
                :date-format-options="dateFormat"
                placeholder="Selecciona una fecha"
              ></b-form-datepicker
            ></b-col>
            <b-col class="col-12 col-md-5 mb-2">
              <label for="end-date">¿Por cuantos dias?</label>
              <b-form-select
                class="mb-2"
                style=""
                v-model="periodo"
                :options="[
                  30,
                  60,
                  90,
                  120,
                  150,
                  180,
                  210,
                  240,
                  270,
                  300,
                  330,
                  360,
                ]"
              ></b-form-select>
            </b-col>
          </b-row>
        </div>
        <div
          v-for="(asegurado, key) in asegurados"
          :key="key"
          class="asegurados bg-white rounded p-3 mb-3"
        >
          <b-form>
            <b-row>
              <b-col class="d-flex align-content-center flex-wrap">
                <div>
                  <h5 class="mt-2 mr-2" v-if="key == 0">Persona a asegurar:</h5>
                  <h5 class="mt-2 mr-2" v-else>Persona a asegurar: Tercero</h5>
                </div>
                <div class="left">
                  <b-form-select
                    class=""
                    :options="[
                      { value: 'tercero', text: 'Asegurado' },
                      { value: 'contratante', text: 'Tomador' },
                    ]"
                    v-model="asegurados[key].tipo"
                    v-if="key == 0"
                    @change="typeChanged"
                  ></b-form-select>
                </div>
                <div class="left ml-1">
                  <b-button v-b-modal.modal-no-backdrop v-if="key == 0"><i class="fas fa-info"></i></b-button>
                    
                  <b-modal id="modal-no-backdrop" hide-backdrop content-class="shadow" title="Persona a asegurar: Tomador" hide-footer>
                    <p>
                      La opcion tomador le permitira emitir una poliza a su nombre para trabajos particulares.
                    </p>
                    <p>
                      En caso de realizar la poliza para algun lugar de trabajo especifico complete el campo tomador: con los datos de la empresa / persona para la que vaya a trabajar y en el campo asegurado sus datos.
                    </p>
                  </b-modal>
                </div>
                <div style="margin-left: auto;">
                  <b-button
                    v-if="key == 0"
                    class="btn-form"
                    @click="agregarAsegurado()"
                    
                  >
                    Agregar <i class="fas fa-user-plus"></i>
                  </b-button>
                  <b-button
                    v-else
                    class="btn-delete ml-auto"
                    @click="quitarAsegurado(key)"
                  >
                    Quitar <i class="fas fa-user-minus"></i>
                  </b-button>
                </div>
              </b-col>



              <b-col
                cols="12"
                v-if="key == 0"
                v-show="!(isSelectedContratante && !user.login)"
              >
                <hr />
                <b-input
                  v-model="domicilioRiesgo"
                  :state="domicilioRiesgoState"
                  placeholder="Domicilio de Riesgo"
                ></b-input>
                <hr />
                <b-row>
                  <b-col class="text-left">
                    <h6 class="d-inline-block mr-2">
                      <b>Cláusula de no repetición</b>
                    </h6>
                  </b-col>
                </b-row>
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="noRepeticion"
                  name="checkbox-1"
                  :value="true"
                  :unchecked-value="false"
                  :state="noRepeticionState"
                >
                  <p>Deseo tener cláusula de no repetición</p>
                </b-form-checkbox>
                <b-row>
                  <b-col cols="12" class="text-left" v-if="noRepeticion">
                    <p>Datos de la empresa</p></b-col
                  >
                  <b-col cols="12" md="6" class="mb-2" v-if="noRepeticion">
                    <b-input
                      v-model="empresaCuit"
                      :state="empresaCuitState"
                      placeholder="Cuit: NN-NNNNNNNN-N"
                      @keydown="preventIncorrectCuitInput($event, empresaCuit)"
                    ></b-input
                  ></b-col>
                  <b-col cols="12" md="6" class="mb-2" v-if="noRepeticion">
                    <b-input
                      v-model="razonSocialEmpresa"
                      :state="razonSocialEmpresaState"
                      placeholder="Razon social"
                    ></b-input></b-col
                ></b-row>
              </b-col>
            </b-row>
            <b-form-row class="position-relative">
              <div class="login" v-show="(isSelectedContratante && !user.login)">
                <div>
                  <h2 class="py-2">Debes inicias sesión o crear una cuenta</h2>

                  <b-button @click="showLogin()" class="mx-2"
                    >Iniciar sesión</b-button
                  >
                  <b-button @click="showRegister()" class="mx-2"
                    >Crear cuenta</b-button
                  >
                </div>
              </div>

              <b-col cols="12 text-left">
                <hr />
                <h6>
                  <b>Asegurado {{ key + 1 }}</b>
                </h6>
              </b-col>
              <b-col md="4" class="mb-2">
                <b-input
                  v-model="asegurados[key].nombre"
                  :state="asegurados[key].nombreState"
                  placeholder="*Nombre"
                ></b-input>
              </b-col>
              <b-col md="4" class="mb-2">
                <b-input
                  v-model="asegurados[key].apellido"
                  :state="asegurados[key].apellidoState"
                  placeholder="*Apellido"
                ></b-input>
              </b-col>
              <b-col md="4" class="">
                <b-form-group class="text-left">
                  <b-form-datepicker
                    v-model="asegurados[key].fechaNacimiento"
                    :state="asegurados[key].fechaNacimientoState"
                    :max="max"
                    locale="es-AR"
                    format="dd/MM/yyyy"
                    placeholder="*Fecha de nacimiento"
                    :date-format-options="dateFormat"
                    show-decade-nav
                  ></b-form-datepicker>
                </b-form-group>
                <!--<datepicker
                  :typeable="true"
                  type="string"
                  :input-class="'form-control ' + fechaClass"
                  format="dd/MM/yyyy"
                  v-model="asegurados[key].fechaNacimiento"
                  placeholder="Fecha de nacimiento"
                  use-utc
                ></datepicker>-->
              </b-col>
              <b-col md="4" class="mb-2">
                <b-form-select
                  :options="options"
                  v-model="asegurados[key].tipoDNI"
                  :state="asegurados[key].tipoDNIState"
                ></b-form-select>
              </b-col>
              <b-col md="4" class="mb-2">
                <b-input
                  v-model="asegurados[key].numeroDocumento"
                  placeholder="*Numero de documento"
                  :state="asegurados[key].numeroDocumentoState"
                  @keypress="isNumber($event)"
                ></b-input>
              </b-col>
              </b-form-row>

              <b-form-row>
              <b-col md="4" class="mb-2">
                <b-form-select
                  :options="provincias"
                  v-model="asegurados[key].provincia"
                  :state="asegurados[key].provinciaState"
                ></b-form-select>
              </b-col>
              <b-col md="4" class="mb-2">
                <b-input
                  v-model="asegurados[key].localidad"
                  placeholder="Localidad"
                  :state="asegurados[key].localidadState"
                ></b-input
              ></b-col>
              <b-col md="3" class="mb-2">
                <b-input
                  v-model="asegurados[key].cp"
                  :state="asegurados[key].cpState"
                  placeholder="Codigo postal"
                  @keypress="isNumber($event)"
                ></b-input
              ></b-col>
              <b-col md="12" class="mb-2">
                <b-input
                  v-model="asegurados[key].domicilio"
                  :state="asegurados[key].domicilioState"
                  placeholder="Domicilio"
                ></b-input
              ></b-col>
              <b-col md="4" class="mb-2">
                <b-input
                  v-model="asegurados[key].telefono"
                  :state="asegurados[key].telefonoState"
                  placeholder="Telefono"
                  @keypress="isNumber($event)"
                ></b-input
              ></b-col>
              <b-col md="4" class="mb-2">
                <b-input
                  v-model="asegurados[key].email"
                  :state="asegurados[key].emailState"
                  placeholder="e-mail"
                ></b-input
              ></b-col>
            </b-form-row>
            <!--<beneficiario-form
              v-show="!(isSelectedContratante && !user.login)"
              v-else
              ref="beneficiario-form"
              @ready="getBeneficiario"
            ></beneficiario-form>-->
          </b-form>
          <h6 class="text-muted text-left"><small>*campos obligatorios</small></h6>
        </div>
      </b-col>

      <b-col cols="12" md="4" class="order-2 order-md-0">
        <div class="text-left resumen bg-white rounded p-3 mb-3">
          <h3>Resumen</h3>
          <hr />
          <h5 class="text-left mb-3">Información</h5>

          <b-table
            class="bg-white text-muted text-left"
            thead-class="d-none"
            hover
            outlined
            :items="resumen"
            style="font-size: 0.8rem"
          >
            <template #cell(descripcion)="data">
              <b class="font-weight-bold text-capitalize">{{ data.value }}</b>
            </template>
          </b-table>

          <h5 class="text-left mb-3">Asegurado/s</h5>
          <div>{{ isSelectedContratante ? "Tomador" : "Asegurado" }}</div>

          <div>

            <b-tabs content-class="p-3" class="bg-white">
              <b-tab title="Coberturas" active>
                <p class="text-left mb-3"><b>Coberturas aseguradas</b></p>

                <b-table
                  class="bg-white small text-muted text-left"
                  thead-class="d-none"
                  hover
                  :items="condiciones"
                >
                  <!-- A virtual composite column -->
                  <template #cell(descripcion)="data">
                    <b class="font-weight-bold text-capitalize">{{
                      data.value
                    }}</b>
                  </template>
                </b-table></b-tab
              >
              <b-tab title="Pago" class="text-left">
                <p><b>Con MercadoPago</b></p>
                <ul>
                  <li>Tarjeta de crédito</li>
                  <li>Tarjeta de débito</li>
                  <li>En efectivo en puntos de pago</li>
                  <li>Dinero en Mercado Pago</li>
                </ul>
              </b-tab>
            </b-tabs>

          </div>

          <div
            class="d-flex justify-content-around grounded bg-light px-2 py-2 align-items-stretch h5"
            style="width: 90%; margin: 20px auto"
          >
            <strong>Total:</strong>
            <span class="">${{ total }} </span>
          </div>
          <div class="text-center">
            <b-button
              class="m-2 btn-oferta"
              size="lg"
              @click="irAContratar()"
              style="width: 90%; margin: 20px auto"
            >
              Pagar<i class="fas fa-file-signature ml-2"></i>
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Api from "@/client/api";
import StepHeader from "@/client/components/layout/StepHeader";
import Provincias from "@/client/data/provincias.js";
//import BeneficiarioForm from "../components/oferta/BeneficiarioForm.vue";

export default {
  name: "Contratar",
  props: {
    fechainicio: {
      types: String,
    },
    fechafin: {
      types: String,
    },
    ofertaId: {
      types: [Number, String],
    },
    user: Object,
  },
  components: { 
    StepHeader, 
    //BeneficiarioForm 
  },
  created() {
    this.loadOferta();
    if (this.fechainicio) {
      this.startDate = this.fechainicio;
    } else {
      const now = new Date();
      this.startDate =
        now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();
    }
    if (this.fechainicio && this.fechafin) {
      var count = 0;

      count = Math.round(
        Math.abs(
          (new Date(this.fechainicio) - new Date(this.fechafin)) / 86400000
        )
      );
      this.periodo = count + 1;
    } else {
      this.periodo = 30;
    }
  },
  beforeMount() {
    this.agregarAsegurado();
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth());
    const minDate = new Date(today);
    const maxDate = new Date(today);
    minDate.setDate(now.getDate() + 1);
    maxDate.setDate(now.getDate() + 365);
    return {
      dateFormat: { year: 'numeric', month: 'numeric', day: 'numeric'},
      fechaClass: "",
      asegurados: [],
      domicilioRiesgo: "",
      domicilioRiesgoState: null,
      noRepeticion: false,
      noRepeticionState: null,
      empresaCuit: "",
      empresaCuitState: null,
      razonSocialEmpresa: "",
      razonSocialEmpresaState: null,
      options: [
        { value: null, text: "*Tipo de documento", disabled: true },
        { value: "DNI", text: "DNI" },
        { value: "CUIT", text: "CUIT" },
        { value: "LC", text: "LC" },
        { value: "LE", text: "LE" },
        { value: "Pasaporte", text: "Pasaporte" },
        { value: "Otro", text: "Otro" },
      ],
      provincias: Provincias,
      oferta: null,
      min: minDate,
      max: maxDate,
      startDate: null,
      periodo: null,
      isSelectedContratante: false
    };
  },
  methods: {
    isNumber (evt) {
        const keysAllowed  = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        const keyPressed = evt.key;
        
        if (!keysAllowed.includes(keyPressed)) {
            evt.preventDefault()
        }
    },
    preventIncorrectCuitInput($event) {
      if ($event.which == 229) {
        return;
      }
      if (/^[a-zA-Z!"#$%&'()*+,./:;<=>?@[\] ^_`{|}~-]$/i.test($event.key)) {
        $event.preventDefault();
        return false;
      }
      if (
        /^[0-9]$/i.test($event.key) &&
        this.empresaCuit &&
        this.empresaCuit.length == 13
      ) {
        $event.preventDefault();
        return false;
      }
      if ($event.key == "Backspace" || $event.key === "Delete") {
        return;
      }
      setTimeout(() => {
        if (/^([0-9]{2})$/g.test(this.empresaCuit)) {
          this.empresaCuit = this.empresaCuit + "-";
        }
        if (/^([0-9]{2})-([0-9]{8})$/g.test(this.empresaCuit)) {
          this.empresaCuit = this.empresaCuit + "-";
        }
        if (/^([0-9]{2})-?([0-9]{8})-?([0-9]{1})$/g.test(this.empresaCuit)) {
          var match = /^([0-9]{2})-?([0-9]{8})-?([0-9]{1})$/g.exec(
            this.empresaCuit
          );
          this.empresaCuit = match[1] + "-" + match[2] + "-" + match[3];
        }
        return true;
      }, 100);
    },

    checkForm() {
      var valid = true;
      if (!this.domicilioRiesgo) valid = false;

      this.domicilioRiesgoState = this.domicilioRiesgo ? true : false;

      if (this.noRepeticion) {
        if (!this.empresaCuit || !this.razonSocialEmpresa) {
          valid = false;
        }
        this.razonSocialEmpresaState = this.razonSocialEmpresa ? true : false;
        this.empresaCuitState = this.empresaCuit ? true : false;
      } else {
        this.empresaCuit = null;
        this.razonSocialEmpresa = null;
        this.razonSocialEmpresaState = null;
        this.empresaCuitState = null;
      }

      this.asegurados.forEach((element) => {
        if (!element.tipoDNI) valid = false;
        element.tipoDNIState = element.tipoDNI ? true : false;

        if (!element.numeroDocumento) valid = false;
        element.numeroDocumentoState = element.numeroDocumento ? true : false;

        if (!element.nombre) valid = false;
        element.nombreState = element.nombre ? true : false;

        if (!element.apellido) valid = false;
        element.apellidoState = element.apellido ? true : false;

        if (!element.fechaNacimiento) {
          valid = false;
          this.fechaClass = "is-invalid";
        } else {
          this.fechaClass = "is-valid";
        }
        element.fechaNacimientoState = element.fechaNacimiento ? true : false;

        /*if (!element.provincia) valid = false;
        element.provinciaState = element.provincia ? true : false;

        if (!element.localidad) valid = false;
        element.localidadState = element.localidad ? true : false;

        if (!element.cp) valid = false;
        element.cpState = element.cp ? true : false;

        if (!element.domicilio) valid = false;
        element.domicilioState = element.domicilio ? true : false;

        if (!element.telefono) valid = false;
        element.telefonoState = element.telefono ? true : false;

        if (!element.email) valid = false;
        element.emailState = element.email ? true : false;*/
      });
      return valid;
    },
    irAContratar() {
      if (this.user.login) {
        if (this.checkForm()) {
          var aseguradosFormat = [];
          if (this.isSelectedContratante) {
            this.asegurados.forEach((element) => {
              aseguradosFormat.push({
                persona_id: element.numeroDocumento,
                domicilio: `${element.domicilio}, ${element.localidad},  ${element.provincia}, CP: ${element.cp} `,
                telefono: element.telefono,
                name: element.nombre,
                apellido: element.apellido,
                fecha_nacimiento: element.fechaNacimiento,
                mail: element.email,
                es_cliente: 1,
                es_asegurado: 1,
                tipo_id: element.tipoDNI,
              });
            });
          } else {
            this.asegurados.forEach((element) => {
              aseguradosFormat.push({
                persona_id: element.numeroDocumento,
                domicilio: `${element.domicilio}, ${element.localidad},  ${element.provincia}, CP: ${element.cp} `,
                telefono: element.telefono,
                name: element.nombre,
                apellido: element.apellido,
                fecha_nacimiento: element.fechaNacimiento,
                mail: element.email,
                es_cliente: 0,
                es_asegurado: 1,
                tipo_id: element.tipoDNI,
              });
            });
            aseguradosFormat.push({
              persona_id: this.user.data.dni,
              domicilio: `${this.user.data.domicilio}, ${this.user.data.localidad},  ${this.user.data.provincia}, CP: ${this.user.data.cp} `,
              telefono: this.user.data.telefono,
              name: this.user.data.name,
              apellido: this.user.data.apellido,
              fecha_nacimiento: this.user.data.fecha_nacimiento,
              mail: this.user.data.email,
              es_cliente: 1,
              es_asegurado: 0,
              tipo_id: this.user.data.tipo_documento,
            });
          }
          Api.poliza({
            fecha_inicio: this.startDate,
            fecha_fin: this.endDate,
            domicilio_riesgo: this.domicilioRiesgo,
            oferta_id: this.ofertaId,
            usuario_cliente_id: this.user.data.id,
            tipo_id: this.user.data.tipo_documento,
            involucrados: aseguradosFormat,
            clausula_norepeticion: this.noRepeticion ? 1 : 0,
            cuit_norepeticion: this.empresaCuit ? this.empresaCuit : "N/A",
            razon_norepeticion: this.razonSocialEmpresa
              ? this.razonSocialEmpresa
              : "N/A",
          })
            .then((res) => {
              console.log(res);

              this.$bvToast.toast(res.message, {
                title: "Correcto",
                autoHideDelay: 5000,
                variant: "success",
              });
              this.$router
                .push({
                  path: `/poliza/${res.poliza_id}`,
                })
                .catch((err) => {
                  err;
                });
            })
            .catch((error) => {
              console.log("Hubo un problema con la petición: " + error.message);
              this.$bvToast.toast(error.message, {
                title: "Error",
                autoHideDelay: 5000,
                variant: "danger",
              });
            });
        } else {
          this.$bvToast.toast("Completá los datos faltantes", {
            title: "Error",
            autoHideDelay: 5000,
            variant: "danger",
          });
        }
      } else {
        this.showLogin();
      }
    },
    /*
    //eliminar si no se usa el componente de beneficiario para modularizar esta pagina
    getBeneficiario(value) {
      this.beneficiario = value;
    },*/
    showRegister() {
      this.$root.$emit("bv::show::modal", "modal-register", "#btnShow");
    },
    showLogin() {
      this.$root.$emit("bv::show::modal", "modal-login", "#btnShow");
    },

    agregarAsegurado() {
      var asegurado = {
        tipo: "tercero",
        tipoDNI: null,
        numeroDocumento: "",
        nombre: "",
        apellido: "",
        provincia: null,
        localidad: "",
        cp: "",
        domicilio: "",
        telefono: "",
        email: "",
        fechaNacimiento: "",
        tipoDNIState: null,
        numeroDocumentoState: null,
        nombreCompletoState: null,
        provinciaState: null,
        localidadState: null,
        cpState: null,
        domicilioState: null,
        telefonoState: null,
        emailState: null,
        fechaNacimientoState: null,
      };
      this.asegurados.push(asegurado);
    },
    quitarAsegurado(index) {
      this.asegurados.splice(index, 1);
    },
    loadOferta() {
      this.isError = false;

      if (this.ofertaId) {
        Api.getOferta(this.ofertaId)
          .then((oferta) => {
            this.oferta = oferta;
            this.isError = false;
          })
          .catch((err) => {
            console.log(err);
            this.isError = true;
          });
      }
    },
    typeChanged(value) {
      if (value == "contratante") {
        this.asegurados.splice(1, this.asegurados.length);
        this.isSelectedContratante = true;
      } else {
        this.isSelectedContratante = false;
      }
    },
    refreshDataContratante() {
      if (this.user.data) {
        if (this.user.data.dni)
          this.asegurados[0].numeroDocumento = this.user.data.dni;
        if (this.user.data.tipo_documento)
          this.asegurados[0].tipoDNI = this.user.data.tipo_documento;
        if (this.user.data.name)
          this.asegurados[0].nombre = this.user.data.name;
        if (this.user.data.apellido)
          this.asegurados[0].apellido = this.user.data.apellido;
        if (this.user.data.provincia)
          this.asegurados[0].provincia = this.user.data.provincia;
        if (this.user.data.localidad)
          this.asegurados[0].localidad = this.user.data.localidad;
        if (this.user.data.cp) this.asegurados[0].cp = this.user.data.cp;
        if (this.user.data.domicilio)
          this.asegurados[0].domicilio = this.user.data.domicilio;
        if (this.user.data.email)
          this.asegurados[0].email = this.user.data.email;
        if (this.user.data.telefono)
          this.asegurados[0].telefono = this.user.data.telefono;
        if (this.user.data.fecha_nacimiento)
          this.asegurados[0].fechaNacimiento = this.user.data.fecha_nacimiento;
        if (this.user.data.provincia)
          this.asegurados[0].provincia = this.user.data.provincia;
      }
    },
  },
  watch: {
    "user.data": function() {
      if (this.isSelectedContratante) {
        this.refreshDataContratante();
      }
    },
    isSelectedContratante: function(value) {
      if (value) {
        this.refreshDataContratante();
      } else {
        this.asegurados.splice(0);
        this.agregarAsegurado();
      }
    },
    rutaNueva: function(value) {
      this.$router.push(value).catch(() => {});
    },
  },
  computed: {
    rutaNueva: function() {
      return `/contratar-${this.ofertaId}/${this.startDate}/${this.endDate}`;
    },
    condiciones: function() {
      let condiciones = [];
      if (this.oferta && this.oferta.atributos) {
        this.oferta.atributos.forEach((element) => {
          if (!element.clausula) {
            condiciones.push({
              descripcion: element.descripcion,
              valor: element.valor,
            });
          }
        });
      }
      return condiciones;
    },
    resumen: function() {
      let resumenItem = [];
      if (this.oferta && this.oferta.aseguradora[0].descripcion) {
        resumenItem.push({
          descripcion: "Aseguradora",
          valor: this.oferta.aseguradora[0].descripcion,
        });
      }
      if (this.dias) {
        resumenItem.push({
          descripcion: "Dias",
          valor: this.dias,
        });
      }
      if (this.startDate) {
        resumenItem.push({
          descripcion: "Comienza",
          valor: this.startDate,
        });
      }
      if (this.endDate) {
        resumenItem.push({
          descripcion: "Finaliza",
          valor: this.endDate,
        });
      }
      if (this.asegurados) {
        resumenItem.push({
          descripcion: "Personas",
          valor: this.asegurados.length,
        });
      }

      if (this.oferta && this.oferta.precio_base) {
        resumenItem.push({
          descripcion: "Precio Base",
          valor: "$" + this.oferta.precio_base,
        });
      }
      if (this.noRepeticion) {
        resumenItem.push({
          descripcion: "Cláusula de no repetición",
          valor: "Si",
        });
      }

      return resumenItem;
    },
    total: function() {
      if (this.asegurados.length > 0 && this.dias > 0 && this.oferta) {
        return (
          Math.round(
            this.oferta.precio_base *
              (this.dias / 30) *
              this.asegurados.length *
              100
          ) / 100
        ).toFixed(2);
      }
      return 0;
    },

    dias: function() {
      var count = 0;
      if (this.startDate && this.endDate) {
        count = Math.round(
          Math.abs(
            (new Date(this.startDate) - new Date(this.endDate)) / 86400000
          )
        );
        return count + 1;
      }
      return null;
    },
    endDate: function() {
      if (this.periodo && this.startDate) {
        let endDate = new Date(this.startDate);

        endDate.setDate(endDate.getDate() + this.periodo - 1);
        return endDate.toISOString().split("T")[0];
      }
      return null;
    },
    maxDate: function() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth());

      let maxEndDate = new Date(today);
      if (this.startDate) {
        maxEndDate = new Date(this.startDate);
        maxEndDate.setDate(maxEndDate.getDate() + 366);
      } else {
        maxEndDate.setDate(now.getDate() + 366);
      }
      return maxEndDate;
    },
    minDate: function() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth());

      let minEndDate = new Date(today);
      if (this.startDate) {
        minEndDate = new Date(this.startDate);
        minEndDate.setDate(minEndDate.getDate() + 30);
      } else {
        minEndDate.setDate(now.getDate() + 30);
      }
      return minEndDate;
    },
  },
};
</script>

<style scoped>
.login {
  position: absolute;
  width: 100%;
  display: flex;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.897);
  z-index: 1001;
  align-items: center;
  justify-content: center;
}

.logoAseguradora {
  max-block-size: 70px;
}
</style>
