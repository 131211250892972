import "@babel/polyfill"

import Vue from 'vue'
import App from './App.vue'

import router from '@/client/router'
import { BootstrapVue, IconsPlugin } from "bootstrap-vue"

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import Datepicker from "vuejs-datepicker"
import {es } from 'vuejs-datepicker/dist/locale'
Datepicker.props.language.default = () => es 
Vue.component('datepicker', Datepicker)

import vSelect from "vue-select"
Vue.component("v-select", vSelect)

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import "vue-select/dist/vue-select.css"

import "@fortawesome/fontawesome-free/css/all.css"
import "@fortawesome/fontawesome-free/js/all.js"

import "@geedmo/yamm/dist/yamm.css"

Vue.config.productionTip = false



import { Datetime } from "vue-datetime"
import "vue-datetime/dist/vue-datetime.css"
Vue.use(Datetime)
Vue.component('datetime', Datetime)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
