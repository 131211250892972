import { render, staticRenderFns } from "./BrHeader.vue?vue&type=template&id=465150c4&scoped=true&"
import script from "./BrHeader.vue?vue&type=script&lang=js&"
export * from "./BrHeader.vue?vue&type=script&lang=js&"
import style0 from "./BrHeader.vue?vue&type=style&index=0&id=465150c4&scoped=true&lang=css&"
import style1 from "./BrHeader.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "465150c4",
  null
  
)

export default component.exports