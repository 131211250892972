<template>
  <b-container>
    <template v-if="user.login">
      <b-row>
        <b-col class="mb-3"> <step-header /> </b-col>
      </b-row>
      <b-row v-if="poliza && oferta">
        <b-col cols="12" class="text-left">
          <div class="text-left resumen bg-white rounded border p-4 mb-3">
            <h3>{{ oferta.aseguradora[0].descripcion }}</h3>
            <b-row>
              <b-col cols="12" md="5" class="mb-2" order-md="1" order="2">
                <p>{{ oferta.detalle ? oferta.detalle : "No detalle" }}</p>
                <!--<p v-if="beneficiario">
                  El beneficiario de la poliza es
                  {{ beneficiario.nombre }} {{ beneficiario.apellido }} 
                  ({{beneficiario.tipo_id }} {{ beneficiario.persona_id }})
                  , con domicilio en {{ beneficiario.domicilio }} 
                  y fecha de nacimiento el {{ beneficiario.fecha_nacimiento }}
                  . Medios de contacto: {{ beneficiario.mail }}, {{ beneficiario.telefono }}.
                </p>-->
                <p v-if="poliza && poliza.clausula_norepeticion">
                  Empresa: {{ poliza.razon_norepeticion }} ({{
                    poliza.cuit_norepeticion
                  }})
                </p>
                <p v-if="poliza">
                  La poliza se encuentra
                  <span class="d-inline-block">
                    {{
                      poliza.estado == 0
                        ? "pendiente de pago"
                        : poliza.estado == 1
                        ? "pagada"
                        : poliza.estado == 3
                        ? "Esperando Confirmacion de pago"
                        : "Plazo de pago vencido"
                    }}</span
                  >.
                </p>
              </b-col>
              <b-col cols="12" md="3" order-md="2" order="3">
                <p><b>Fecha de inicio:</b> {{ poliza.inicio_vigencia }}</p>
                <p><b>Fecha de fin:</b> {{ poliza.fin_vigencia }}</p>
                <p>
                  <b>Cantidad de asegurados contratados:</b>
                  {{ poliza.cantidad_asegurados }}
                </p>
                <p>
                  <b>Cantidad de dias:</b>
                  {{ dias }}
                </p>
                <p v-if="poliza && poliza.clausula_norepeticion">
                  <b>Cláusula de no repetición:</b>
                  {{ poliza.clausula_norepeticion ? "si" : " no" }}
                </p>
              </b-col>
              <b-col cols="12" md="4" class="text-center" order-md="3" order="1"
                ><b-img
                  class="mb-3"
                  :src="
                    oferta.aseguradora[0].logo
                      ? oferta.aseguradora[0].logo
                      : 'https://via.placeholder.com/150C'
                  "
                  fluid
                  alt="Fluid image"
                ></b-img
              ></b-col>
            </b-row>
            <hr />
            <b-row align-h="between">
              <b-col cols="4">
                <b-button
                  v-if="poliza.estado > 0"
                  class="m-2 btn-oferta"
                  style="white-space: nowrap"
                  @click.prevent.stop="descargarComprobante()"
                  ><i class="fas fa-file-pdf"></i> &nbsp;Descargar el
                  comprobante
                </b-button>
              </b-col>
              <b-col cols="4" class="text-right">
                <form action="/coberturas" v-if="poliza.estado == 0">
                  <script
                    data-button-label="Pagar Tramite"
                    type="application/javascript"
                    src="https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js"
                    :data-preference-id="poliza.token_pago"
                  ></script>
                </form>
                <b-button
                  v-else
                  class="m-2"
                  style="white-space: nowrap"
                  target="_blank"
                  href=""
                  rel="noopener noreferrer"
                  :disabled="!poliza.estado == 0"
                  :class="{
                    'btn-success': poliza.estado == 1,
                    'btn-primary': poliza.estado == 0,
                    'btn-danger': poliza.estado == 2,
                  }"
                  ><i
                    :class="{
                      'fas fa-check-square': poliza.estado == 1,
                      'fas fa-credit-card': poliza.estado == 0,
                      'fas fa-exclamation-triangle': poliza.estado == 2,
                    }"
                  ></i>

                  {{
                    poliza.estado == 1
                      ? "Pagado"
                      : poliza.estado == 3
                      ? "Procesando"
                      : "Plazo de pago excedido, contactenos"
                  }}
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>

      <b-row v-else>
        <b-col class="mb-3"> Tramite no Encontrado </b-col>
      </b-row>

      <b-row v-if="poliza">
        <b-col cols="12" class="text-left">
          <div class="text-left resumen bg-white border rounded p-4 mb-3">
            <b-row>
              <b-col cols="12">
                <h3>Asegurados</h3>
                <p>Domicilio de riesgo: {{ poliza.domicilio_riesgo }}</p>
              </b-col>
              <b-col cols="">
                <b-table fixed responsive :items="asegurados" :fields="fields">
                  <template #cell(show_details)="row">
                    <b-modal id="modal-1" title="Contactá con nosotros" ok-only>
                      <p class="my-4">
                        Para modificaciones en la tramite por favor contactá con
                        nosotros a: Email: consultas@brokerandino.com.ar
                        Teléfono: 4214273 Celu y WP 2645144910-2644685648.
                      </p>
                    </b-modal>
                    <b-button size="sm" class="mr-1" @click="row.toggleDetails">
                      <i class="fas fa-info-circle"></i>
                    </b-button>
                  </template>
                  <template #row-details="row">
                    <b-card>
                      <b-row class="mb-2">
                        <b-col sm="3" class="text-sm-right"
                          ><b>Domiclio:</b></b-col
                        >
                        <b-col>{{ row.item.domicilio }}</b-col>
                      </b-row>

                      <b-row class="mb-2">
                        <b-col sm="3" class="text-sm-right"
                          ><b>Fecha de nacimiento:</b></b-col
                        >
                        <b-col>{{ row.item.fecha_nacimiento }}</b-col>
                      </b-row>

                      <b-row class="mb-2">
                        <b-col sm="3" class="text-sm-right"
                          ><b>E-mail:</b></b-col
                        >
                        <b-col>{{ row.item.mail }}</b-col>
                      </b-row>

                      <b-row class="mb-2">
                        <b-col sm="3" class="text-sm-right"
                          ><b>Telefono:</b></b-col
                        >
                        <b-col>{{ row.item.telefono }}</b-col>
                      </b-row>

                      <b-button size="sm" @click="row.toggleDetails"
                        >Ocultar</b-button
                      >
                    </b-card>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col>
                <p><b>Total de la poliza:</b> ${{ total }}</p></b-col
              >
              <b-col> </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="mb-3">
            <b-tabs content-class="p-3" class="bg-white">
              <b-tab title="Coberturas" active>
                <p class="text-left mb-3"><b>Coberturas aseguradas</b></p>

                <b-table
                  class="bg-white text-muted text-left"
                  thead-class="d-none"
                  hover
                  :items="condiciones"
                >
                  <!-- A virtual composite column -->
                  <template #cell(descripcion)="data">
                    <b class="font-weight-bold text-capitalize">{{
                      data.value
                    }}</b>
                  </template>
                </b-table></b-tab
              >
              <b-tab title="Pago" class="text-left">
                <p><b>Con MercadoPago</b></p>
                <ul>
                  <li>Tarjeta de crédito</li>
                  <li>Tarjeta de débito</li>
                  <li>En efectivo en puntos de pago</li>
                  <li>Dinero en Mercado Pago</li>
                </ul>
              </b-tab>
            </b-tabs>
          </div>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-row>
        <b-col class="bg-white rounded border p-3 mb-3">
          <h5>Debes iniciar sesión</h5></b-col
        ></b-row
      >
    </template>
  </b-container>
</template>

<script>
import StepHeader from "@/client/components/layout/StepHeader";
import Api from "@/client/api.js";

export default {
  name: "poliza",
  props: {
    id: {
      types: [Number, String],
    },
    user: Object,
  },
  components: { StepHeader },
  async created() {
    await Api.getPoliza(this.id).then((res) => {
      this.poliza = res;
    });
    await Api.getOfertaAll(this.poliza.oferta_id).then((res) => {
      this.oferta = res;
    });
  },
  data() {
    return {
      poliza: null,
      oferta: null,
      fields: [
        { key: "name", label: "Nombre" },
        { key: "apellido", label: "Apellido" },
        { key: "identificacion", label: "Identificación" },
        { key: "show_details", label: "Acciones" },
      ],
    };
  },
  methods: {
    descargarComprobante() {
      Api.getPolizaFile(
        this.poliza.id,
        `${this.oferta.aseguradora[0].descripcion} ${this.poliza.inicio_vigencia}`
      ).catch((error) => {
        console.log("Hubo un problema con la petición: " + error.message);
        this.$bvToast.toast(error.message, {
          title: "Error",
          autoHideDelay: 5000,
          variant: "danger",
        });
      });
    },
  },
  computed: {
    condiciones: function() {
      let condiciones = [];
      if (this.oferta && this.oferta.atributos) {
        this.oferta.atributos.forEach((element) => {
          if (!element.clausula) {
            condiciones.push({
              descripcion: element.descripcion,
              valor: element.valor,
            });
          }
        });
      }
      return condiciones;
    },
    total: function() {
      if (this.poliza && this.dias > 0) {
        return (
          Math.round(
            this.poliza.precio_base *
              (this.dias / 30) *
              this.poliza.cantidad_asegurados *
              100
          ) / 100
        ).toFixed(2);
      }
      return 0;
    },
    dias: function() {
      var count = 0;
      if (this.poliza.inicio_vigencia && this.poliza.fin_vigencia) {
        count = Math.round(
          Math.abs(
            (new Date(this.poliza.inicio_vigencia) -
              new Date(this.poliza.fin_vigencia)) /
              86400000
          )
        );
        return count + 1;
      }
      return null;
    },
    asegurados: function() {
      var asegurados = [];
      if (this.poliza && this.poliza.involucrados) {
        this.poliza.involucrados.forEach((involucrado) => {
          if (involucrado.es_asegurado == 1) {
            asegurados.push({
              name: involucrado.name,
              apellido: involucrado.apellido,
              mail: involucrado.mail,
              telefono: involucrado.telefono,
              domicilio: involucrado.domicilio,
              fecha_nacimiento: involucrado.fecha_nacimiento,
              identificacion:
                involucrado.tipo_id + " " + involucrado.persona_id,
            });
          }
        });
      }
      return asegurados;
    },
    /*beneficiario: function () {
      var beneficiario = {};
      if (this.poliza && this.poliza.involucrados) {
        this.poliza.involucrados.forEach((involucrado) => {
          if (involucrado.es_beneficiario == 1) {
            beneficiario = involucrado;
          }
        });
      }
      return beneficiario;
    },*/
  },
  watch: {
    user: function(value) {
      console.log(value);
      if (this.user.login) {
        this.created();
      }
    },
  },
};
</script>

<style>
.mercadopago-button {
  margin: 0.5rem !important;
  padding: 0.375rem 0.75rem !important;
  font-family: "Helvetica Neue", Arial, sans-serif !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  background: #27337a !important;
  border-radius: 0.25rem !important;
  color: #fff !important;
  cursor: pointer !important;
  border: 0 !important;
}
</style>
